import React from 'react';
import { Typography, Box, makeStyles } from '@material-ui/core';
import { ReactComponent as Quote } from '../../../images/auth/quote.svg';

const useStyles = makeStyles({
  body: {
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '45px',
    letterSpacing: '1px',
    marginBottom: '30px',
    marginTop: '-24px',
  },
  footer: {
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '1px',
    color: '#F6F9FE',
  },
});
const CEOComment = () => {
  const styles = useStyles();
  return (
    <>
      <Box ml="-20px">
        <Quote />
      </Box>
      <Typography className={styles.body}>
        As the leading EduTech company in Indonesia, we use Skuad’s self-serve
        platform to hire highly skilled IT engineers and remain compliant as we
        pay and manage a global team.
      </Typography>
      <Typography className={styles.footer}>
        Rohan Monga · CEO · Zenius
      </Typography>
    </>
  );
};

export default CEOComment;
