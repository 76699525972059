import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import * as _ from 'lodash';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { validateFields } from '../../utils/validator';
import { confirmPasswordReset } from '../firebase/helper';
import ThankyouText from '../common/ThankyouText';
import ErrorText from '../common/ErrorText';
import CustomButton from '../common/CustomButton';
import FormInput from '../common/FormInput';
import CustomCard from '../common/CustomCard';
import { PASSWORD_SUGGESTION_MESSAGE } from '../../utils/constants';

const useStyles = makeStyles({
  centerAll: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  resetPasswordWrapper: {
    height: '100%',
    maxHeight: '100%',
    overflow: 'auto',
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 10,
  },
  header: {
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '33px',
    textAlign: 'center',
    color: '#06132D',
    marginBottom: '23px',
  },
  description: {
    fontSize: '16px',
    lineHeight: '26px',
    textAlign: 'center',
    color: '#3E4659',
    marginBottom: '17px',
    width: '70%',
    margin: '0 auto',
  },
});

const PasswordResetInput = props => {
  const [password, setPassword] = useState('');
  const [thankyou, setThankyou] = useState(false);
  const [error, setError] = useState({});

  useEffect(() => {
    props.setView && props.setView('reset');
  }, []);
  const validateForm = () => {
    let error = {};
    let isValidate = true;

    let validatePassword = validateFields('password', password);
    if (validatePassword) {
      _.set(error, 'password', validatePassword);
    }

    if (!_.isEmpty(error)) {
      isValidate = false;
    }
    setError(error);
    return isValidate;
  };

  const continueClick = async () => {
    window.location.replace(`${decodeURIComponent(props.redirect_url)}`);
  };

  const handleSubmit = async function (e) {
    e.preventDefault();

    if (validateForm()) {
      props.startPageLoader();
      try {
        await confirmPasswordReset(props.code, password);
        props.stopPageLoader();
        setThankyou(true);
      } catch (error) {
        props.showSnackbar(error.message);
        props.stopPageLoader();
        return;
      }
    }
  };

  const classes = useStyles();

  return (
    <Box
      className={clsx(
        classes.resetPasswordWrapper,
        classes.centerAll,
        'hide-scroll'
      )}
      width="100%"
    >
      {thankyou ? (
        <ThankyouText
          {...props}
          heading="Password reset successfully"
          continueClick={continueClick}
        />
      ) : (
        <CustomCard className="w-100">
          <Typography className={classes.header}>Reset Password</Typography>
          <form className="w-100" onSubmitCapture={e => handleSubmit(e)}>
            <FormInput
              className="w-100"
              name="password"
              type="password"
              label="Password"
              required
              value={password}
              handleChange={e => setPassword(e.target.value)}
              error={!_.isEmpty(_.get(error, ['password'], ''))}
              variant="outlined"
            />
            {!_.isEmpty(_.get(error, ['password'], '')) ? (
              <ErrorText
                error={error}
                name={'password'}
                tooltip={`${PASSWORD_SUGGESTION_MESSAGE}`}
              />
            ) : (
              <Typography variant="body2" color="textSecondary">
                {PASSWORD_SUGGESTION_MESSAGE}
              </Typography>
            )}
            <Box mt={2} className="w-100">
              <CustomButton
                className="w-100 form-action-button"
                variant="contained"
                color="primary"
                custom
                fullwidth="true"
                onClick={e => handleSubmit(e)}
              >
                Reset Password
              </CustomButton>
            </Box>
          </form>
        </CustomCard>
      )}
    </Box>
  );
};

export default PasswordResetInput;
