import axios from 'axios';
import { API_ENDPOINTS } from './endpoint';
class API {
  constructor() {
    this._baseURL = process.env.REACT_APP_API_BASE_URL;
    this._basicAuthCredentials = {
      username: process.env.BASIC_AUTH_USERNAME,
      password: process.env.BASIC_AUTH_PASSWORD,
    };
    this._basicAuth = null;
    this._authless = null;
  }

  get baseURL() {
    return this._baseURL;
  }

  get basicAuthCredentials() {
    return this._basicAuthCredentials;
  }

  get basicAuth() {
    if (!this._basicAuth) {
      this._basicAuth = axios.create({
        baseURL: this.baseURL,
        withCredentials: true,
        // auth: this.basicAuthCredentials
      });
    }
    return this._basicAuth;
  }

  get authless() {
    if (!this._authless) {
      this._authless = axios.create({
        baseURL: this.baseURL,
      });
    }
    return this._authless;
  }

  showError(err, enqueueSnackbar, options = {}) {
    let errorMessage = typeof err === 'string' ? err : err && err.message;
    errorMessage && enqueueSnackbar && enqueueSnackbar(errorMessage, options);
  }
}
const api = new API();

const loginFunction = async (
  data,
  loginMode = 'hire',
  context = 'firebase'
) => {
  data.context = context;
  try {
    return await api.authless.post(API_ENDPOINTS[loginMode], data);
  } catch (error) {
    if (error.response?.data && error.response?.data?.message)
      error.message = error.response?.data?.message;
    if (error.response?.data && error.response?.data?.code)
      error.code = error.response?.data?.code;
    throw error;
  }
};

const getInviteFunction = async id => {
  try {
    let resp = await api.authless.get(`${API_ENDPOINTS['invite']}/${id}`);
    return resp.data;
  } catch (error) {
    if (error.response.data && error.response.data.message)
      error.message = error.response.data.message;
    if (error.response.data && error.response.data.code)
      error.code = error.response.data.code;
    throw error;
  }
};

const sendPasswordResetInviteLink = async (mode, data) => {
  try {
    return await api.authless.post(`/auth/${mode}/resetLink`, data);
  } catch (error) {
    if (error.response.data && error.response.data.message)
      error.message = error.response.data.message;
    if (error.response.data && error.response.data.code)
      error.code = error.response.data.code;
    throw error;
  }
};

const verifyEmail = async data => {
  try {
    if (data && data.password) {
      return await api.authless.post(`/auth/verify-secondary-email`, {
        ...data,
      });
    }
    return await api.authless.post(`/auth/verify-email`);
  } catch (error) {
    if (error.response.data && error.response.data.message)
      error.message = error.response.data.message;
    if (error.response.data && error.response.data.code)
      error.code = error.response.data.code;
    throw error;
  }
};

const validateIdentifier = async identifier => {
  try {
    const resp = await api.authless.post(`/auth/validate/identifier`, {
      identifier,
    });
    return resp.data;
  } catch (error) {
    if (error.response.data && error.response.data.message)
      error.message = error.response.data.message;
    if (error.response.data && error.response.data.code)
      error.code = error.response.data.code;
    throw error;
  }
};

export {
  loginFunction,
  getInviteFunction,
  sendPasswordResetInviteLink,
  verifyEmail,
  validateIdentifier,
};
