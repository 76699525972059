import React, { useEffect, useState } from 'react';
import { Card, CardContent, Box, Typography } from '@material-ui/core';
import Img from '../common/Img';
import Logo from '../../images/logo.svg';
import LOGIN_MODES from '../common/config';

const Container = props => {
  useEffect(() => {
    document.title = props.title || '';
  }, []);
  return (
    <div className="my-auto w-100" style={{ maxWidth: '420px' }}>
      <div className="m-md-4 m-lg-0 m-4">
        <div className="formcontainer m-auto">
          <div className="mb-3">
            <div
              className="sk-title"
              style={{
                color: props.css && props.isMobile ? props.css.mobileColor : '',
              }}
            >
              {props.loginMode.views[props.view].heading || props.heading}
            </div>
            <div
              className="sk-sub-title"
              style={{
                color: props.css && props.isMobile ? props.css.mobileColor : '',
              }}
            >
              {props.loginMode.views[props.view].subHeading || props.subHeading}
            </div>
          </div>
          {props.cloneView ? (
            <>{React.cloneElement(props.children, { ...props })}</>
          ) : (
            <>{props.children}</>
          )}
          <Box>
            {props.loginMode.views[props.view].textArray?.map(textObj => (
              <Box my={3} color="white">
                <Typography variant="h4" gutterBottom component="div">
                  {textObj?.heading}
                </Typography>
              </Box>
            ))}
          </Box>
        </div>
      </div>
    </div>
  );
  //   return (<div className="center-all">
  //   <Card className="m-md-4 m-4 w-100" style={{"maxWidth":"420px"}}>
  //     <CardContent>
  //       <div className="formcontainer position-relative">
  //         {!props.disableImg && (
  //           <div className="d-flex justify-content-center align-items-center flex-column mb-5">
  //             <Img src={props.loginMode.illustration.logo} />
  //           </div>
  //         )}
  //         {props.children}
  //       </div>
  //     </CardContent>
  //   </Card>
  // </div>);
};

export default Container;
