import React, { useState } from 'react';
import * as _ from 'lodash';
import {
  Typography,
  makeStyles,
  Box,
  FormControlLabel,
  Checkbox,
  Card,
} from '@material-ui/core';
import NewGoogleButton from '../forms/NewGoogleButton';
import FormInput from '../common/FormInput';
import { Grid } from '@material-ui/core';
import DoubleText from './DoubleText';
import CustomButton from '../common/CustomButton';
import {
  showSnackbar,
  startPageLoader,
  stopPageLoader,
} from '../../redux/actions/appAction';
import {
  signInWithPopup,
  signOutUser,
  signUpWithEmailAndPassword,
  updateUserDetails,
} from '../firebase/helper';
import { successRedirectionCopy } from '../common/helper';
import { validateFields } from '../../utils/validator';
import { useDispatch } from 'react-redux';
import {
  COMPANY_EMAIL_LABEL,
  COMPANY_EMAIL_PLACEHOLDER,
  GENERIC_EMAIL_LABEL,
  GENERIC_EMAIL_PLACEHOLDER,
  PASSWORD_SUGGESTION_MESSAGE,
  ScreenType,
  USER_ROLE,
} from '../../utils/constants';
import { payCreateUserMeta, payGetUserRole } from '../../utils/generic';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
  },
  header: {
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '33px',
    textAlign: 'center',
    color: '#06132D',
    marginBottom: '30px',
  },
  card: {
    padding: '30px',
    boxShadow: '0px 6px 12px rgba(10, 4, 60, 0.15)',
    borderRadius: '10px',
  },
  privacy: {
    color: '#3E4659',
    paddingLeft: '12px',
  },
  invite: {
    fontSize: '16px',
    lineHeight: '26px',
    textAlign: 'center',
    color: '#2D3748',
    margin: '20px auto 30px',
  },
});

const CreateAccount = ({ setScreenType, user, setUser, invited, ...props }) => {
  const classes = useStyles();
  const _dispatch = useDispatch();

  const [newsLetterSubscription, setNewsLetterSubscription] = useState(false);
  const [showPasswordMessage, setShowPasswordMessage] = useState(false);

  const isEmployer = user?.role === USER_ROLE.Company;

  const [signupForm, setSignupForm] = useState({
    name: props?.inviteUser?.name || '',
    email: props?.inviteUser?.email || '',
    companyName: props?.inviteUser?.companyName || '',
    password: '',
  });

  const [error, setError] = useState({
    name: '',
    companyName: '',
    email: '',
    password: '',
  });

  const handleSignUpWithGoogle = async () => {
    _dispatch(startPageLoader());
    try {
      const authRes = await signInWithPopup('google.com');

      if (
        !_.isEmpty(props.inviteUser) &&
        authRes?.user?.email !== signupForm.email
      ) {
        throw new Error('Please sign in using your invite email address');
      }

      const meta = payCreateUserMeta(user, { newsLetterSubscription });

      const res = await successRedirectionCopy(props, '', meta);
      if (res?.data?.success) {
        await signOutUser();
        window.location.replace(`${decodeURIComponent(props.redirectURL)}`);
      } else {
        if (!user?.type) {
          const type = res?.data?.meta?.type;
          setUser({ role: payGetUserRole(type), type });
        }
        setScreenType(ScreenType.AdditionalInfo);
        _dispatch(stopPageLoader());
      }
    } catch (error) {
      _dispatch(stopPageLoader());
      _dispatch(showSnackbar(error.message));
    }
  };

  const validateForm = () => {
    const formError = {};
    const { name, companyName, password, email } = signupForm;

    const validateName = validateFields('name', name);
    const validateCompanyName = isEmployer
      ? validateFields('companyName', companyName)
      : null;
    const validatePassword = validateFields('password', password);
    const validateEmail = validateFields('email', email, { role: user?.role });

    _.set(formError, 'name', validateName);
    _.set(formError, 'name', validateCompanyName);
    _.set(formError, 'password', validatePassword);
    _.set(formError, 'email', validateEmail);

    if (
      _.isEmpty(formError.email) &&
      _.isEmpty(formError.password) &&
      _.isEmpty(formError.name)
    ) {
      if (isEmployer && _.isEmpty(formError.companyName)) {
        return true;
      }
      return true;
    }

    setError(formError);

    return false;
  };

  const handleChange = e => {
    e.persist();
    setSignupForm(form => ({
      ...form,
      [e.target.name]: e.target.value,
    }));
    setError(error => ({
      ...error,
      [e.target.name]: validateFields(e.target.name, e.target.value, {
        role: user?.role,
      }),
    }));
  };

  const handleSigninWithEmailAndPassword = async e => {
    e.preventDefault();
    if (validateForm()) {
      _dispatch(startPageLoader());
      try {
        await signUpWithEmailAndPassword(signupForm.email, signupForm.password);
        await updateUserDetails(signupForm.name.trim());

        const meta = payCreateUserMeta(user, {
          newsLetterSubscription,
          companyName: signupForm.companyName,
        });
        const res = await successRedirectionCopy(props, '', meta);
        if (res?.data?.success) {
          await signOutUser();
          window.location.replace(`${decodeURIComponent(props.redirectURL)}`);
        } else {
          if (!user?.type) {
            const type = res?.data?.meta?.type;
            setUser({ role: payGetUserRole(type), type });
          }
          setScreenType(ScreenType.AdditionalInfo);
          _dispatch(stopPageLoader());
        }
      } catch (error) {
        _dispatch(showSnackbar(error.message));
        _dispatch(stopPageLoader());
      }
    }
  };

  const emailLabel =
    user?.role === USER_ROLE.Individual
      ? GENERIC_EMAIL_LABEL
      : COMPANY_EMAIL_LABEL;
  const emailPlaceholder =
    user?.role === USER_ROLE.Individual
      ? GENERIC_EMAIL_PLACEHOLDER
      : COMPANY_EMAIL_PLACEHOLDER;

  return (
    <Box pb="25px" className={classes.wrapper}>
      <Typography className={classes.header}>Create Your Account</Typography>
      {!_.isEmpty(props?.inviteUser) &&
        [USER_ROLE.Individual, USER_ROLE.Company].includes(user?.role) && (
          <Typography className={classes.invite}>
            {`${
              props?.inviteUser?.companyName || 'Skuad'
            } has invited you to the platform to be part of their remote
              team`}
          </Typography>
        )}

      <NewGoogleButton
        text="Sign up with Google"
        handleClick={handleSignUpWithGoogle}
      />
      <Box className="separator" margin="22px 0">
        &nbsp; OR &nbsp;
      </Box>
      <form onSubmit={handleSigninWithEmailAndPassword}>
        <Card className={classes.card}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <FormInput
                id="field1"
                name="name"
                label="Your Full Name"
                placeholder="Please provide your full name here"
                handleChange={handleChange}
                error={!!error.name}
                helperText={error.name}
                defaultValue={props?.inviteUser?.name}
                required
              />
            </Grid>
            {isEmployer ? (
              <Grid item xs={12}>
                <FormInput
                  id="companyName"
                  name="companyName"
                  label="Company Name"
                  placeholder="Please provide company legal name here"
                  handleChange={handleChange}
                  error={!!error.companyName}
                  helperText={error.companyName}
                  defaultValue={signupForm?.companyName}
                  disabled={!!props?.inviteUser?.companyName}
                  required
                />
              </Grid>
            ) : null}

            <Grid item xs={12} md={12}>
              <FormInput
                id="field2"
                name="email"
                label={emailLabel}
                placeholder={emailPlaceholder}
                type="email"
                handleChange={handleChange}
                error={!!error.email}
                helperText={error.email}
                defaultValue={props?.inviteUser?.email}
                disabled={!!props?.inviteUser?.email}
                required
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <FormInput
                id="field3"
                name="password"
                label="Create your password"
                type="password"
                handleChange={handleChange}
                error={!!error.password}
                helperText={error.password}
                required
                onFocus={() => setShowPasswordMessage(true)}
                onBlur={() => {
                  setTimeout(() => {
                    setShowPasswordMessage(false);
                  }, 100);
                }}
              />
              {_.isEmpty(_.get(error, ['password'], '')) &&
                showPasswordMessage && (
                  <Typography
                    component={Box}
                    mt="16px !important"
                    variant="body1"
                    color="textSecondary"
                  >
                    {PASSWORD_SUGGESTION_MESSAGE}
                  </Typography>
                )}
            </Grid>
            <FormControlLabel
              className={classes.privacy}
              control={
                <Checkbox
                  color="primary"
                  checked={newsLetterSubscription}
                  onChange={e => setNewsLetterSubscription(e.target.checked)}
                  name="privacy"
                />
              }
              label="I wish to receive updates and the latest info from Skuad."
            />
            <Box pl="12px">
              <DoubleText
                size={16}
                color="#3E4659"
                preText="By signing up you agree to our"
                suffText="Terms of Service & Privacy Policy."
                link="https://www.skuad.io/privacy"
              />
            </Box>
            <Box pl="12px" mt="25px" mb="15px" width="100%">
              <CustomButton variant="contained" custom type="submit" fullWidth>
                Create Account
              </CustomButton>
            </Box>
          </Grid>
        </Card>
      </form>
      <Box textAlign="center" mt="30px">
        <DoubleText
          size={16}
          color="#3E4659"
          preText="Already have an account?"
          suffText="Sign in"
          handleClick={() => {
            setScreenType(ScreenType.SignIn, null);
            if (!invited) {
              setUser({});
            }
          }}
        />
      </Box>
    </Box>
  );
};

export default CreateAccount;
