import React, { useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import CustomButton from './CustomButton';
import CustomCard from './CustomCard';

const ThankyouText = props => {
  const { heading, subHeading } = props;

  useEffect(() => {
    props.setView && props.setView('thankyou');
    props.setSubHeading && props.setSubHeading(props.subtext);
  }, []);

  return (
    <Box
      maxHeight="100%"
      overflow="auto"
      pl="10px"
      pr="10px"
      pb="10px"
      className="hide-scroll"
      width="100%"
    >
      <CustomCard
        display="flex"
        flexDirection="column"
        className="text-center w-100"
      >
        {!!heading && (
          <Typography variant="h2" component={Box} mb={2}>
            {heading}
          </Typography>
        )}
        {!!subHeading && (
          <Typography
            variant="body1"
            color="textSecondary"
            component={Box}
            mb="15px !important"
          >
            {subHeading}
          </Typography>
        )}
        <CustomButton
          className="w-100 form-action-button"
          variant="contained"
          color="primary"
          custom
          onClick={props.continueClick}
        >
          Continue
        </CustomButton>
      </CustomCard>
    </Box>
  );
};

export default ThankyouText;
