import React from 'react';
import VerticalList from '../../../components/List/VerticalList';

const EmployerBenefits = () => {
  const benefits = [
    {
      title: 'Hire in 150+ countries',
      description:
        'Let Skuad be your global expansion partner. Through our network of local entities we make hiring effortless...as it should be!',
    },
    {
      title: 'One click payment to pay all remote workers',
      description:
        'Skuad takes away the headache of making payments to your global workforce in multiple currencies. You pay in one currency while Skuad works its magic to make sure everyone gets paid on time.',
    },
    {
      title: 'Skuad Shield to manage all labour compliances',
      description:
        'Local labour laws can be tricky to understand. Skuad ensures that compliance is met by collecting necessary documents and avoiding misclassification.',
    },
  ];
  return <VerticalList list={benefits} header="Employer Benefits" />;
};

export default EmployerBenefits;
