const countryList = [
  {
    label: 'Afghanistan',
    dial_code: '+93',
    value: 'AF',
    nationality: 'Afghan',
  },
  {
    label: 'Aland Islands',
    dial_code: '+358',
    value: 'AX',
    nationality: 'Åland Islands',
  },
  {
    label: 'Albania',
    dial_code: '+355',
    value: 'AL',
    nationality: 'Albanian',
    currLabel: 'Albania Lek - ALL',
    currValue: 'ALL',
  },
  {
    label: 'Algeria',
    dial_code: '+213',
    value: 'DZ',
    nationality: 'Algerian',
    currLabel: 'Algerian Dinar - DZD',
    currValue: 'DZD',
  },
  {
    label: 'American Samoa',
    dial_code: '+1 684',
    value: 'AS',
    nationality: 'American Samoan',
  },
  {
    label: 'Andorra',
    dial_code: '+376',
    value: 'AD',
    nationality: 'Andorran',
  },
  {
    label: 'Angola',
    dial_code: '+244',
    value: 'AO',
    nationality: 'Angolan',
    currLabel: 'Angolan Kwanza - AOA',
    currValue: 'AOA',
  },
  {
    label: 'Anguilla',
    dial_code: '+1 264',
    value: 'AI',
    nationality: 'Anguillian',
  },
  {
    label: 'Antarctica',
    dial_code: '+672',
    value: 'AQ',
    nationality: 'Antarctica',
  },
  {
    label: 'Antigua and Barbuda',
    dial_code: '+1268',
    value: 'AG',
    nationality: 'Antiguans',
  },
  {
    label: 'Argentina',
    dial_code: '+54',
    value: 'AR',
    nationality: 'Argentinean',
    currLabel: 'Argentina Peso - ARS',
    currValue: 'ARS',
  },
  {
    label: 'Armenia',
    dial_code: '+374',
    value: 'AM',
    nationality: 'Armenian',
  },
  {
    label: 'Aruba',
    dial_code: '+297',
    value: 'AW',
    nationality: 'Aruban',
  },
  {
    label: 'Australia',
    dial_code: '+61',
    value: 'AU',
    nationality: 'Australian',
    currLabel: 'Australia Dollar - AUD',
    currValue: 'AUD',
  },
  {
    label: 'Austria',
    dial_code: '+43',
    value: 'AT',
    nationality: 'Austrian',
    currLabel: 'Austria Schilling - ATS',
    currValue: 'ATS (EURO)',
  },
  {
    label: 'Azerbaijan',
    dial_code: '+994',
    value: 'AZ',
    nationality: 'Azerbaijani',
  },
  {
    label: 'Bahamas',
    dial_code: '+1 242',
    value: 'BS',
    nationality: 'Bahamian',
  },
  {
    label: 'Bahrain',
    dial_code: '+973',
    value: 'BH',
    nationality: 'Bahraini',
    currLabel: 'Bahrain Dinar - BHD',
    currValue: 'BHD',
  },
  {
    label: 'Bangladesh',
    dial_code: '+880',
    value: 'BD',
    nationality: 'Bangladeshi',
    currLabel: 'Bangladesh Taka - BDT',
    currValue: 'BDT',
  },
  {
    label: 'Barbados',
    dial_code: '+1 246',
    value: 'BB',
    nationality: 'Barbadian',
  },
  {
    label: 'Belarus',
    dial_code: '+375',
    value: 'BY',
    nationality: 'Belarusian',
    currLabel: 'Belarus Ruble - BYR',
    currValue: 'BYR',
  },
  {
    label: 'Belgium',
    dial_code: '+32',
    value: 'BE',
    nationality: 'Belgian',
    currLabel: 'Belgium Franc - BEF',
    currValue: 'BEF (EURO)',
  },
  {
    label: 'Belize',
    dial_code: '+501',
    value: 'BZ',
    nationality: 'Belizean',
  },
  {
    label: 'Benin',
    dial_code: '+229',
    value: 'BJ',
    nationality: 'Beninese',
  },
  {
    label: 'Bermuda',
    dial_code: '+1 441',
    value: 'BM',
    nationality: 'Bermuda',
  },
  {
    label: 'Bhutan',
    dial_code: '+975',
    value: 'BT',
    nationality: 'Bhutanese',
  },
  {
    label: 'Bolivia, Plurinational State of',
    dial_code: '+591',
    value: 'BO',
    nationality: 'Bolivian',
  },
  {
    label: 'Bosnia and Herzegovina',
    dial_code: '+387',
    value: 'BA',
    nationality: 'Bosnian - Herzegovinian',
    currLabel: 'Bosnian Convirtable Marks - BAM',
    currValue: 'BAM',
  },
  {
    label: 'Botswana',
    dial_code: '+267',
    value: 'BW',
    nationality: 'Batswana',
    currLabel: 'Botswanan Pula - BWP',
    currValue: 'BWP',
  },
  {
    label: 'Brazil',
    dial_code: '+55',
    value: 'BR',
    nationality: 'Brazilian',
    currLabel: 'Brazil Real - BRL',
    currValue: 'BRL',
  },
  {
    label: 'British Indian Ocean Territory',
    dial_code: '+246',
    value: 'IO',
    nationality: 'British Indian Ocean Territory',
  },
  {
    label: 'Brunei Darussalam',
    dial_code: '+673',
    value: 'BN',
    nationality: 'Bruneian',
  },
  {
    label: 'Bulgaria',
    dial_code: '+359',
    value: 'BG',
    nationality: 'Bulgarian',
    currLabel: 'Bulgaria Lev - BGN',
    currValue: 'BGN',
  },
  {
    label: 'Burkina Faso',
    dial_code: '+226',
    value: 'BF',
    nationality: 'Burkinabe',
    currLabel: 'West African CFA Franc - XOF',
    currValue: 'XOF',
  },
  {
    label: 'Burundi',
    dial_code: '+257',
    value: 'BI',
    nationality: 'Burundian',
  },
  {
    label: 'Cambodia',
    dial_code: '+855',
    value: 'KH',
    nationality: 'Cambodian',
    currLabel: 'Cambodia Riel - KHR',
    currValue: 'KHR',
  },
  {
    label: 'Cameroon',
    dial_code: '+237',
    value: 'CM',
    nationality: 'Cameroonian',
    currLabel: 'Central African CFA Franc - XAF',
    currValue: 'XAF',
  },
  {
    label: 'Canada',
    dial_code: '+1',
    value: 'CA',
    nationality: 'Canadian',
    currLabel: 'Canada Dollar - CAD',
    currValue: 'CAD',
  },
  {
    label: 'Cape Verde',
    dial_code: '+238',
    value: 'CV',
    nationality: 'Cape Verdean',
  },
  {
    label: 'Cayman Islands',
    dial_code: '+ 345',
    value: 'KY',
    nationality: 'Cayman Islands',
  },
  {
    label: 'Central African Republic',
    dial_code: '+236',
    value: 'CF',
    nationality: 'Central African',
  },
  {
    label: 'Chad',
    dial_code: '+235',
    value: 'TD',
    nationality: 'Chadian',
    currLabel: 'Central African CFA Franc - XAF',
    currValue: 'XAF',
  },
  {
    label: 'Chile',
    dial_code: '+56',
    value: 'CL',
    nationality: 'Chilean',
    currLabel: 'Chilean Peso - CLP',
    currValue: 'CLP',
  },
  {
    label: 'China',
    dial_code: '+86',
    value: 'CN',
    nationality: 'Chinese',
    currLabel: 'China Yuan/Renminbi - CNY',
    currValue: 'CNY',
  },
  {
    label: 'Christmas Island',
    dial_code: '+61',
    value: 'CX',
    nationality: 'Christmas Island',
  },
  {
    label: 'Cocos (Keeling) Islands',
    dial_code: '+61',
    value: 'CC',
    nationality: 'Cocos (Keeling) Islands',
  },
  {
    label: 'Colombia',
    dial_code: '+57',
    value: 'CO',
    nationality: 'Colombian',
    currLabel: 'Colombia Peso - COP',
    currValue: 'COP',
  },
  {
    label: 'Comoros',
    dial_code: '+269',
    value: 'KM',
    nationality: 'Comoran',
  },
  {
    label: 'Congo',
    dial_code: '+242',
    value: 'CG',
    nationality: 'Congolese',
  },
  {
    label: 'Congo, The Democratic Republic of the Congo',
    dial_code: '+243',
    value: 'CD',
    nationality: 'Congolese',
    currLabel: 'Congolese Franc - CDF',
    currValue: 'CDF',
  },
  {
    label: 'Cook Islands',
    dial_code: '+682',
    value: 'CK',
    nationality: 'Cook Islands',
  },
  {
    label: 'Costa Rica',
    dial_code: '+506',
    value: 'CR',
    nationality: 'Costa Rican',
    currLabel: 'Costa Rica Colon - CRC',
    currValue: 'CRC',
  },
  {
    label: "Cote d'Ivoire",
    dial_code: '+225',
    value: 'CI',
    nationality: 'Ivorian',
  },
  {
    label: 'Croatia',
    dial_code: '+385',
    value: 'HR',
    nationality: 'Croatian',
    currLabel: 'Croatia Kuna - HRK',
    currValue: 'HRK',
  },
  {
    label: 'Cuba',
    dial_code: '+53',
    value: 'CU',
    nationality: 'Cuban',
  },
  {
    label: 'Cyprus',
    dial_code: '+357',
    value: 'CY',
    nationality: 'Cypriot',
    currLabel: 'Cyprus Pound - CYP',
    currValue: 'CYP (EURO)',
  },
  {
    label: 'Czech Republic',
    dial_code: '+420',
    value: 'CZ',
    nationality: 'Czech',
    currLabel: 'Czech Koruna - CZK',
    currValue: 'CZK',
  },
  {
    label: 'Denmark',
    dial_code: '+45',
    value: 'DK',
    nationality: 'Danish',
  },
  {
    label: 'Djibouti',
    dial_code: '+253',
    value: 'DJ',
    nationality: 'Djibouti',
  },
  {
    label: 'Dominica',
    dial_code: '+1 767',
    value: 'DM',
    nationality: 'Dominican',
  },
  {
    label: 'Dominican Republic',
    dial_code: '+1 849',
    value: 'DO',
    nationality: 'Dominican',
  },
  {
    label: 'Ecuador',
    dial_code: '+593',
    value: 'EC',
    nationality: 'Ecuadorean',
  },
  {
    label: 'Egypt',
    dial_code: '+20',
    value: 'EG',
    nationality: 'Egyptian',
    currLabel: 'Egyptian Pound - EGP',
    currValue: 'EGP',
  },
  {
    label: 'El Salvador',
    dial_code: '+503',
    value: 'SV',
    nationality: 'Salvadoran',
    currLabel: 'El Salvador Colon - SVC',
    currValue: 'SVC',
  },
  {
    label: 'Equatorial Guinea',
    dial_code: '+240',
    value: 'GQ',
    nationality: 'Equatorial Guinean',
  },
  {
    label: 'Eritrea',
    dial_code: '+291',
    value: 'ER',
    nationality: 'Eritrean',
  },
  {
    label: 'Estonia',
    dial_code: '+372',
    value: 'EE',
    nationality: 'Estonian',
    currLabel: 'Estonia Kroon - EEK',
    currValue: 'EEK (EURO)',
  },
  {
    label: 'Ethiopia',
    dial_code: '+251',
    value: 'ET',
    nationality: 'Ethiopian',
    currLabel: 'Ethopian Birr - ETB',
    currValue: 'ETB',
  },
  {
    label: 'Falkland Islands (Malvinas)',
    dial_code: '+500',
    value: 'FK',
    nationality: 'Falkland Islands (Malvinas)',
  },
  {
    label: 'Faroe Islands',
    dial_code: '+298',
    value: 'FO',
    nationality: 'Faroe Islands',
  },
  {
    label: 'Fiji',
    dial_code: '+679',
    value: 'FJ',
    nationality: 'Fijian',
  },
  {
    label: 'Finland',
    dial_code: '+358',
    value: 'FI',
    nationality: 'Finnish',
    currLabel: 'Finland Markka - FIM',
    currValue: 'FIM (EURO)',
  },
  {
    label: 'France',
    dial_code: '+33',
    value: 'FR',
    nationality: 'French',
    currLabel: 'French Francs - FRF',
    currValue: 'FRF',
  },
  {
    label: 'French Guiana',
    dial_code: '+594',
    value: 'GF',
    nationality: 'French Guianese',
  },
  {
    label: 'French Polynesia',
    dial_code: '+689',
    value: 'PF',
    nationality: 'French Polynesian',
  },
  {
    label: 'Gabon',
    dial_code: '+241',
    value: 'GA',
    nationality: 'Gabonese',
  },
  {
    label: 'Gambia',
    dial_code: '+220',
    value: 'GM',
    nationality: 'Gambian',
    currLabel: 'Gambian Dalasi - GMD',
    currValue: 'GMD',
  },
  {
    label: 'Georgia',
    dial_code: '+995',
    value: 'GE',
    nationality: 'Georgian',
    currLabel: 'Georgia Lari - GEL',
    currValue: 'GEL',
  },
  {
    label: 'Germany',
    dial_code: '+49',
    value: 'DE',
    nationality: 'German',
    currLabel: 'Germany Mark - DMK',
    currValue: 'DMK (EURO)',
  },
  {
    label: 'Ghana',
    dial_code: '+233',
    value: 'GH',
    nationality: 'Ghanaian',
  },
  {
    label: 'Gibraltar',
    dial_code: '+350',
    value: 'GI',
    nationality: 'Gibraltar',
  },
  {
    label: 'Greece',
    dial_code: '+30',
    value: 'GR',
    nationality: 'Greek',
    currLabel: 'Greece Drachma - GRD',
    currValue: 'GRD (EURO)',
  },
  {
    label: 'Greenland',
    dial_code: '+299',
    value: 'GL',
    nationality: 'Greenland',
  },
  {
    label: 'Grenada',
    dial_code: '+1 473',
    value: 'GD',
    nationality: 'Grenadian',
  },
  {
    label: 'Guadeloupe',
    dial_code: '+590',
    value: 'GP',
    nationality: 'Guadeloupe',
  },
  {
    label: 'Guam',
    dial_code: '+1 671',
    value: 'GU',
    nationality: 'Guam',
  },
  {
    label: 'Guatemala',
    dial_code: '+502',
    value: 'GT',
    nationality: 'Guatemalan',
    currLabel: 'Guatemala Quetzal - GTQ',
    currValue: 'GTQ',
  },
  {
    label: 'Guernsey',
    dial_code: '+44',
    value: 'GG',
    nationality: 'Guernsey',
  },
  {
    label: 'Guinea',
    dial_code: '+224',
    value: 'GN',
    nationality: 'Guinean',
  },
  {
    label: 'Guinea-Bissau',
    dial_code: '+245',
    value: 'GW',
    nationality: 'Guinea-Bissauan',
  },
  {
    label: 'Guyana',
    dial_code: '+595',
    value: 'GY',
    nationality: 'Guyanese',
  },
  {
    label: 'Haiti',
    dial_code: '+509',
    value: 'HT',
    nationality: 'Haitian',
  },
  {
    label: 'Holy See (Vatican City State)',
    dial_code: '+379',
    value: 'VA',
    nationality: 'Holy See (Vatican City State)',
  },
  {
    label: 'Honduras',
    dial_code: '+504',
    value: 'HN',
    nationality: 'Honduran',
  },
  {
    label: 'Hong Kong',
    dial_code: '+852',
    value: 'HK',
    nationality: 'Hong Kong',
  },
  {
    label: 'Hungary',
    dial_code: '+36',
    value: 'HU',
    nationality: 'Hungarian',
    currLabel: 'Hungary Forint - HUF',
    currValue: 'HUF',
  },
  {
    label: 'Iceland',
    dial_code: '+354',
    value: 'IS',
    nationality: 'Icelandic',
    currLabel: 'Iceland Krona - ISK',
    currValue: 'ISK',
  },
  {
    label: 'India',
    dial_code: '+91',
    value: 'IN',
    nationality: 'Indian',
    currLabel: 'India Rupee - INR',
    currValue: 'INR',
  },
  {
    label: 'Indonesia',
    dial_code: '+62',
    value: 'ID',
    nationality: 'Indonesian',
    currLabel: 'Indonesia Rupiah - IDR',
    currValue: 'IDR',
  },
  {
    label: 'Iran, Islamic Republic of Persian Gulf',
    dial_code: '+98',
    value: 'IR',
    nationality: 'Iranian',
  },
  {
    label: 'Iraq',
    dial_code: '+964',
    value: 'IQ',
    nationality: 'Iraqi',
    currLabel: 'Iraq Dinar - IQD',
    currValue: 'IQD',
  },
  {
    label: 'Ireland',
    dial_code: '+353',
    value: 'IE',
    nationality: 'Irish',
  },
  {
    label: 'Isle of Man',
    dial_code: '+44',
    value: 'IM',
    nationality: 'Isle of Man',
  },
  {
    label: 'Israel',
    dial_code: '+972',
    value: 'IL',
    nationality: 'Israeli',
    currLabel: 'Israel New Shekel - ILS',
    currValue: 'ILS',
  },
  {
    label: 'Italy',
    dial_code: '+39',
    value: 'IT',
    nationality: 'Italian',
    currLabel: 'Italy Lira - ITL',
    currValue: 'ITL (EURO)',
  },
  {
    label: 'Jamaica',
    dial_code: '+1 876',
    value: 'JM',
    nationality: 'Jamaican',
    currLabel: 'Jamaica Dollar - JMD',
    currValue: 'JMD',
  },
  {
    label: 'Japan',
    dial_code: '+81',
    value: 'JP',
    nationality: 'Japanese',
    currLabel: 'Japan Yen - JPY',
    currValue: 'JPY',
  },
  {
    label: 'Jersey',
    dial_code: '+44',
    value: 'JE',
    nationality: 'Jersey',
  },
  {
    label: 'Jordan',
    dial_code: '+962',
    value: 'JO',
    nationality: 'Jordanian',
    currLabel: 'Jordan Dinar - JOD',
    currValue: 'JOD',
  },
  {
    label: 'Kazakhstan',
    dial_code: '+7 7',
    value: 'KZ',
    nationality: 'Kazakhstani',
  },
  {
    label: 'Kenya',
    dial_code: '+254',
    value: 'KE',
    nationality: 'Kenyan',
    currLabel: 'Kenyan Shillings - KES',
    currValue: 'KES',
  },
  {
    label: 'Kiribati',
    dial_code: '+686',
    value: 'KI',
    nationality: 'I-Kiribati',
  },
  {
    label: "Korea, Democratic People's Republic of Korea",
    dial_code: '+850',
    value: 'KP',
    nationality: 'North Korean',
  },
  {
    label: 'Korea, Republic of South Korea',
    dial_code: '+82',
    value: 'KR',
    nationality: 'South Korean',
    currLabel: 'South Korean Won - KRW',
    currValue: 'KRW',
  },
  {
    label: 'Kuwait',
    dial_code: '+965',
    value: 'KW',
    nationality: 'Kuwaiti',
    currLabel: 'Kuwait Dinar - KWD',
    currValue: 'KWD',
  },
  {
    label: 'Kyrgyzstan',
    dial_code: '+996',
    value: 'KG',
    nationality: 'Kyrgyz',
    currLabel: 'Kyrgyzstan Som - KGS',
    currValue: 'KGS',
  },
  {
    label: 'Laos',
    dial_code: '+856',
    value: 'LA',
    nationality: 'Laotian',
  },
  {
    label: 'Latvia',
    dial_code: '+371',
    value: 'LV',
    nationality: 'Latvian',
    currLabel: 'Latvia Lats - LVL',
    currValue: 'LVL (EURO)',
  },
  {
    label: 'Lebanon',
    dial_code: '+961',
    value: 'LB',
    nationality: 'Lebanese',
    currLabel: 'Lebanon Pound - LBP',
    currValue: 'LBP',
  },
  {
    label: 'Lesotho',
    dial_code: '+266',
    value: 'LS',
    nationality: 'Mosotho',
  },
  {
    label: 'Liberia',
    dial_code: '+231',
    value: 'LR',
    nationality: 'Liberian',
  },
  {
    label: 'Libyan Arab Jamahiriya',
    dial_code: '+218',
    value: 'LY',
    nationality: 'Libyan',
    currLabel: 'Libyan Dinar - LYD',
    currValue: 'LYD',
  },
  {
    label: 'Liechtenstein',
    dial_code: '+423',
    value: 'LI',
    nationality: 'Liechtensteiner',
  },
  {
    label: 'Lithuania',
    dial_code: '+370',
    value: 'LT',
    nationality: 'Lithuanian',
    currLabel: 'Lithuania Litas - LTL',
    currValue: 'LTL (EURO)',
  },
  {
    label: 'Luxembourg',
    dial_code: '+352',
    value: 'LU',
    nationality: 'Luxembourger',
    currLabel: 'Luxembourg Franc - LUF',
    currValue: 'LUF (EURO)',
  },
  {
    label: 'Macao',
    dial_code: '+853',
    value: 'MO',
    nationality: 'Macao',
  },
  {
    label: 'Macedonia',
    dial_code: '+389',
    value: 'MK',
    nationality: 'Macedonian',
    currLabel: 'Macedonian Denars - MKD',
    currValue: 'MKD',
  },
  {
    label: 'Madagascar',
    dial_code: '+261',
    value: 'MG',
    nationality: 'Malagasy',
    currLabel: 'Malagasy Ariary - MGA',
    currValue: 'MGA',
  },
  {
    label: 'Malawi',
    dial_code: '+265',
    value: 'MW',
    nationality: 'Malawian',
  },
  {
    label: 'Malaysia',
    dial_code: '+60',
    value: 'MY',
    nationality: 'Malaysian',
    currLabel: 'Malaysia Ringgit - MYR',
    currValue: 'MYR',
  },
  {
    label: 'Maldives',
    dial_code: '+960',
    value: 'MV',
    nationality: 'Maldivan',
    currLabel: 'Maldives Rufiyaa - MVR',
    currValue: 'MVR',
  },
  {
    label: 'Mali',
    dial_code: '+223',
    value: 'ML',
    nationality: 'Malian',
  },
  {
    label: 'Malta',
    dial_code: '+356',
    value: 'MT',
    nationality: 'Maltese',
    currLabel: 'Malta Lira - MTL',
    currValue: 'MTL (EURO)',
  },
  {
    label: 'Marshall Islands',
    dial_code: '+692',
    value: 'MH',
    nationality: 'Marshallese',
  },
  {
    label: 'Martinique',
    dial_code: '+596',
    value: 'MQ',
    nationality: 'Martinique',
  },
  {
    label: 'Mauritania',
    dial_code: '+222',
    value: 'MR',
    nationality: 'Mauritanian',
  },
  {
    label: 'Mauritius',
    dial_code: '+230',
    value: 'MU',
    nationality: 'Mauritian',
    currLabel: 'Mauritian Rupees - MUR',
    currValue: 'MUR',
  },
  {
    label: 'Mayotte',
    dial_code: '+262',
    value: 'YT',
    nationality: 'Mayotte',
  },
  {
    label: 'Mexico',
    dial_code: '+52',
    value: 'MX',
    nationality: 'Mexican',
  },
  {
    label: 'Micronesia, Federated States of Micronesia',
    dial_code: '+691',
    value: 'FM',
    nationality: 'Micronesian',
  },
  {
    label: 'Moldova',
    dial_code: '+373',
    value: 'MD',
    nationality: 'Moldovan',
    currLabel: 'Moldovan Lei - MDL',
    currValue: 'MDL',
  },
  {
    label: 'Monaco',
    dial_code: '+377',
    value: 'MC',
    nationality: 'Monacan',
  },
  {
    label: 'Mongolia',
    dial_code: '+976',
    value: 'MN',
    nationality: 'Mongolian',
  },
  {
    label: 'Montenegro',
    dial_code: '+382',
    value: 'ME',
    nationality: 'Montenegro',
    currLabel: 'Euro - EUR',
    currValue: 'EUR',
  },
  {
    label: 'Montserrat',
    dial_code: '+1664',
    value: 'MS',
    nationality: 'Montserrat',
  },
  {
    label: 'Morocco',
    dial_code: '+212',
    value: 'MA',
    nationality: 'Moroccan',
    currLabel: 'Moroccan Dirham - MAD',
    currValue: 'MAD',
  },
  {
    label: 'Mozambique',
    dial_code: '+258',
    value: 'MZ',
    nationality: 'Mozambican',
    currLabel: 'Mozambican Meticais - MZN',
    currValue: 'MZN',
  },
  {
    label: 'Myanmar',
    dial_code: '+95',
    value: 'MM',
    nationality: 'Burmese',
    currLabel: 'Myanmar Kyat - MMK',
    currValue: 'MMK',
  },
  {
    label: 'Namibia',
    dial_code: '+264',
    value: 'NA',
    nationality: 'Namibian',
    currLabel: 'Namibian Dollar - NAD',
    currValue: 'NAD',
  },
  {
    label: 'Nauru',
    dial_code: '+674',
    value: 'NR',
    nationality: 'Nauruan',
  },
  {
    label: 'Nepal',
    dial_code: '+977',
    value: 'NP',
    nationality: 'Nepalese',
    currLabel: 'Nepal Rupee - NPR',
    currValue: 'NPR',
  },
  {
    label: 'Netherlands',
    dial_code: '+31',
    value: 'NL',
    nationality: 'Dutch',
  },
  {
    label: 'Netherlands Antilles',
    dial_code: '+599',
    value: 'AN',
    nationality: 'Dutch',
  },
  {
    label: 'New Caledonia',
    dial_code: '+687',
    value: 'NC',
    nationality: 'New Caledonia',
  },
  {
    label: 'New Zealand',
    dial_code: '+64',
    value: 'NZ',
    nationality: 'New Zealander',
    currLabel: 'New Zealand Dollar - NZD',
    currValue: 'NZD',
  },
  {
    label: 'Nicaragua',
    dial_code: '+505',
    value: 'NI',
    nationality: 'Nicaraguan',
  },
  {
    label: 'Niger',
    dial_code: '+227',
    value: 'NE',
    nationality: 'Nigerian',
  },
  {
    label: 'Nigeria',
    dial_code: '+234',
    value: 'NG',
    nationality: 'Nigerien',
    currLabel: 'Nigeria Naira - NGN',
    currValue: 'NGN',
  },
  {
    label: 'Niue',
    dial_code: '+683',
    value: 'NU',
    nationality: 'Niue',
  },
  {
    label: 'Norfolk Island',
    dial_code: '+672',
    value: 'NF',
    nationality: 'Norfolk Island',
  },
  {
    label: 'Northern Mariana Islands',
    dial_code: '+1 670',
    value: 'MP',
    nationality: 'Northern Mariana Islands',
  },
  {
    label: 'Norway',
    dial_code: '+47',
    value: 'NO',
    nationality: 'Norwegian',
    currLabel: 'Norway Kroner - NOK',
    currValue: 'NOK',
  },
  {
    label: 'Oman',
    dial_code: '+968',
    value: 'OM',
    nationality: 'Omani',
    currLabel: 'Oman Rial - OMR',
    currValue: 'OMR',
  },
  {
    label: 'Pakistan',
    dial_code: '+92',
    value: 'PK',
    nationality: 'Pakistani',
    currLabel: 'Pakistan Rupee - PKR',
    currValue: 'PKR',
  },
  {
    label: 'Palau',
    dial_code: '+680',
    value: 'PW',
    nationality: 'Palauan',
  },
  {
    label: 'Palestinian Territory, Occupied',
    dial_code: '+970',
    value: 'PS',
    nationality: 'Palestinian Territory, Occupied',
    currLabel: 'Israel New Shekel - ILS',
    currValue: 'ILS',
  },
  {
    label: 'Panama',
    dial_code: '+507',
    value: 'PA',
    nationality: 'Panamanian',
    currLabel: 'Panama Balboa - PAB',
    currValue: 'PAB',
  },
  {
    label: 'Papua New Guinea',
    dial_code: '+675',
    value: 'PG',
    nationality: 'Papua New Guinea',
  },
  {
    label: 'Paraguay',
    dial_code: '+595',
    value: 'PY',
    nationality: 'Paraguayan',
  },
  {
    label: 'Peru',
    dial_code: '+51',
    value: 'PE',
    nationality: 'Peruvian',
  },
  {
    label: 'Philippines',
    dial_code: '+63',
    value: 'PH',
    nationality: 'Filipino',
    currLabel: 'Philippine peso - PHP',
    currValue: 'PHP',
  },
  {
    label: 'Pitcairn',
    dial_code: '+872',
    value: 'PN',
    nationality: 'Pitcairn',
  },
  {
    label: 'Poland',
    dial_code: '+48',
    value: 'PL',
    nationality: 'Polish',
    currLabel: 'Poland Zloty - PLN',
    currValue: 'PLN',
  },
  {
    label: 'Portugal',
    dial_code: '+351',
    value: 'PT',
    nationality: 'Portuguese',
    currLabel: 'Portugal Escudo - PTE',
    currValue: 'PTE (EURO)',
  },
  {
    label: 'Puerto Rico',
    dial_code: '+1 939',
    value: 'PR',
    nationality: 'Puerto Rico',
    currLabel: 'United States Dollars - USD',
    currValue: 'USD',
  },
  {
    label: 'Qatar',
    dial_code: '+974',
    value: 'QA',
    nationality: 'Qatari',
    currLabel: 'Qatar Rial - QAR',
    currValue: 'QAR',
  },
  {
    label: 'Romania',
    dial_code: '+40',
    value: 'RO',
    nationality: 'Romanian',
    currLabel: 'Romania New Lei - RON',
    currValue: 'RON',
  },
  {
    label: 'Russia',
    dial_code: '+7',
    value: 'RU',
    nationality: 'Russian',
    currLabel: 'Russian Ruble - RUB',
    currValue: 'RUB',
  },
  {
    label: 'Rwanda',
    dial_code: '+250',
    value: 'RW',
    nationality: 'Rwandan',
    currLabel: 'Rwanda Franc - RWF',
    currValue: 'RWF',
  },
  {
    label: 'Reunion',
    dial_code: '+262',
    value: 'RE',
    nationality: 'Reunion',
  },
  {
    label: 'Saint Barthelemy',
    dial_code: '+590',
    value: 'BL',
    nationality: 'Saint Barthélemy',
  },
  {
    label: 'Saint Helena, Ascension and Tristan Da Cunha',
    dial_code: '+290',
    value: 'SH',
    nationality: 'Saint Helena',
  },
  {
    label: 'Saint Kitts and Nevis',
    dial_code: '+1 869',
    value: 'KN',
    nationality: 'Kittian and Nevisian',
  },
  {
    label: 'Saint Lucia',
    dial_code: '+1 758',
    value: 'LC',
    nationality: 'Saint Lucian',
  },
  {
    label: 'Saint Martin',
    dial_code: '+590',
    value: 'MF',
    nationality: 'Saint Martin (French part)',
  },
  {
    label: 'Saint Pierre and Miquelon',
    dial_code: '+508',
    value: 'PM',
    nationality: 'Saint Pierre and Miquelon',
  },
  {
    label: 'Saint Vincent and the Grenadines',
    dial_code: '+1 784',
    value: 'VC',
    nationality: 'Saint Vincent and the Grenadines',
  },
  {
    label: 'Samoa',
    dial_code: '+685',
    value: 'WS',
    nationality: 'Samoan',
  },
  {
    label: 'San Marino',
    dial_code: '+378',
    value: 'SM',
    nationality: 'San Marinese',
  },
  {
    label: 'Sao Tome and Principe',
    dial_code: '+239',
    value: 'ST',
    nationality: 'Sao Tomean',
  },
  {
    label: 'Saudi Arabia',
    dial_code: '+966',
    value: 'SA',
    nationality: 'Saudi',
    currLabel: 'Saudi Arabian Riyal - SAR',
    currValue: 'SAR',
  },
  {
    label: 'Senegal',
    dial_code: '+221',
    value: 'SN',
    nationality: 'Senegalese',
    currLabel: 'West African CFA Franc - XOF',
    currValue: 'XOF',
  },
  {
    label: 'Serbia',
    dial_code: '+381',
    value: 'RS',
    nationality: 'Serbian',
    currLabel: 'Serbia Dinar - RSD',
    currValue: 'RSD',
  },
  {
    label: 'Seychelles',
    dial_code: '+248',
    value: 'SC',
    nationality: 'Seychellois',
    currLabel: 'Seychelles Rupee - SCR',
    currValue: 'SCR',
  },
  {
    label: 'Sierra Leone',
    dial_code: '+232',
    value: 'SL',
    nationality: 'Sierra Leonean',
    currLabel: 'Sierra Leone Leone - SLL',
    currValue: 'SLL',
  },
  {
    label: 'Singapore',
    dial_code: '+65',
    value: 'SG',
    nationality: 'Singaporean',
    currLabel: 'Singapore Dollar - SGD',
    currValue: 'SGD',
  },
  {
    label: 'Slovakia',
    dial_code: '+421',
    value: 'SK',
    nationality: 'Slovakian',
    currLabel: 'Slovakia Koruna - SKK',
    currValue: 'SKK (EURO)',
  },
  {
    label: 'Slovenia',
    dial_code: '+386',
    value: 'SI',
    nationality: 'Slovenian',
    currLabel: 'Slovenia Tolar - SIT',
    currValue: 'SIT (EURO)',
  },
  {
    label: 'Solomon Islands',
    dial_code: '+677',
    value: 'SB',
    nationality: 'Solomon Islander',
  },
  {
    label: 'Somalia',
    dial_code: '+252',
    value: 'SO',
    nationality: 'Somali',
  },
  {
    label: 'South Africa',
    dial_code: '+27',
    value: 'ZA',
    nationality: 'South African',
    currLabel: 'South Africa Rand - ZAF',
    currValue: 'ZAF',
  },
  {
    label: 'South Georgia and the South Sandwich Islands',
    dial_code: '+500',
    value: 'GS',
    nationality: 'South Georgia and the South Sandwich Islands',
  },
  {
    label: 'Spain',
    dial_code: '+34',
    value: 'ES',
    nationality: 'Spanish',
    currLabel: 'Spain Peseta - ESP',
    currValue: 'ESP (EURO)',
  },
  {
    label: 'Sri Lanka',
    dial_code: '+94',
    value: 'LK',
    nationality: 'Sri Lankan',
    currLabel: 'Sri Lanka Rupee - LKR',
    currValue: 'LKR',
  },
  {
    label: 'Sudan',
    dial_code: '+249',
    value: 'SD',
    nationality: 'Sudanese',
  },
  {
    label: 'Suriname',
    dial_code: '+597',
    value: 'SR',
    nationality: 'Surinamese',
  },
  {
    label: 'Svalbard and Jan Mayen',
    dial_code: '+47',
    value: 'SJ',
    nationality: 'Svalbard and Jan Mayen',
  },
  {
    label: 'Swaziland',
    dial_code: '+268',
    value: 'SZ',
    nationality: 'Swazi',
  },
  {
    label: 'Sweden',
    dial_code: '+46',
    value: 'SE',
    nationality: 'Swedish',
    currLabel: 'Sweden Krona - SEK',
    currValue: 'SEK',
  },
  {
    label: 'Switzerland',
    dial_code: '+41',
    value: 'CH',
    nationality: 'Swiss',
  },
  {
    label: 'Syrian Arab Republic',
    dial_code: '+963',
    value: 'SY',
    nationality: 'Syrian',
  },
  {
    label: 'Taiwan',
    dial_code: '+886',
    value: 'TW',
    nationality: 'Taiwanese',
  },
  {
    label: 'Tajikistan',
    dial_code: '+992',
    value: 'TJ',
    nationality: 'Tajik',
    currLabel: 'Tajikistani Somoni - TJS',
    currValue: 'TJS',
  },
  {
    label: 'Tanzania, United Republic of Tanzania',
    dial_code: '+255',
    value: 'TZ',
    nationality: 'Tanzanian',
    currLabel: 'Tanzanian Shillings - TZS',
    currValue: 'TZS',
  },
  {
    label: 'Thailand',
    dial_code: '+66',
    value: 'TH',
    nationality: 'Thai',
    currLabel: 'Thailand Baht - THB',
    currValue: 'THB',
  },
  {
    label: 'Timor-Leste',
    dial_code: '+670',
    value: 'TL',
    nationality: 'East Timorese',
  },
  {
    label: 'Togo',
    dial_code: '+228',
    value: 'TG',
    nationality: 'Togolese',
    currLabel: 'West African CFA Franc - XOF',
    currValue: 'XOF',
  },
  {
    label: 'Tokelau',
    dial_code: '+690',
    value: 'TK',
    nationality: 'Tokelau',
  },
  {
    label: 'Tonga',
    dial_code: '+676',
    value: 'TO',
    nationality: 'Tongan',
  },
  {
    label: 'Trinidad and Tobago',
    dial_code: '+1 868',
    value: 'TT',
    nationality: 'Trinidadian or Tobagonian',
    currLabel: 'Trinidadian Dollars - TTD',
    currValue: 'TTD',
  },
  {
    label: 'Tunisia',
    dial_code: '+216',
    value: 'TN',
    nationality: 'Tunisian',
    currLabel: 'Tunisia Dinar - TND',
    currValue: 'TND',
  },
  {
    label: 'Turkey',
    dial_code: '+90',
    value: 'TR',
    nationality: 'Turkish',
    currLabel: 'Turkish Lira - TRY',
    currValue: 'TRY',
  },
  {
    label: 'Turkmenistan',
    dial_code: '+993',
    value: 'TM',
    nationality: 'Turkmenistan',
  },
  {
    label: 'Turks and Caicos Islands',
    dial_code: '+1 649',
    value: 'TC',
    nationality: 'Turks and Caicos Islands',
  },
  {
    label: 'Tuvalu',
    dial_code: '+688',
    value: 'TV',
    nationality: 'Tuvaluan',
  },
  {
    label: 'Uganda',
    dial_code: '+256',
    value: 'UG',
    nationality: 'Ugandan',
    currLabel: 'Uganda Shilling - UGX',
    currValue: 'UGX',
  },
  {
    label: 'Ukraine',
    dial_code: '+380',
    value: 'UA',
    nationality: 'Ukrainian',
    currLabel: 'Ukraine Hryvnia - UAH',
    currValue: 'UAH',
  },
  {
    label: 'United Arab Emirates',
    dial_code: '+971',
    value: 'AE',
    nationality: 'Emirian',
    currLabel: 'United Arab Emirates Dirham - AED',
    currValue: 'AED',
  },
  {
    label: 'United Kingdom',
    dial_code: '+44',
    value: 'GB',
    nationality: 'British',
    currLabel: 'Pound Sterlings - GBP',
    currValue: 'GBP',
  },
  {
    label: 'United States',
    dial_code: '+1',
    value: 'US',
    nationality: 'American',
    currLabel: 'United States Dollars - USD',
    currValue: 'USD',
  },
  {
    label: 'Uruguay',
    dial_code: '+598',
    value: 'UY',
    nationality: 'Uruguayan',
  },
  {
    label: 'Uzbekistan',
    dial_code: '+998',
    value: 'UZ',
    nationality: 'Uzbekistani',
    currLabel: 'Uzbekistan Som - UZS',
    currValue: 'UZS',
  },
  {
    label: 'Vanuatu',
    dial_code: '+678',
    value: 'VU',
    nationality: 'Vanuatu',
  },
  {
    label: 'Venezuela, Bolivarian Republic of Venezuela',
    dial_code: '+58',
    value: 'VE',
    nationality: 'Venezuelan',
  },
  {
    label: 'Vietnam',
    dial_code: '+84',
    value: 'VN',
    nationality: 'Vietnamese',
    currLabel: 'Vietnamese Dong - VND',
    currValue: 'VND',
  },
  {
    label: 'Virgin Islands, British',
    dial_code: '+1 284',
    value: 'VG',
    nationality: 'Virgin Islands, British',
  },
  {
    label: 'Virgin Islands, U.S.',
    dial_code: '+1 340',
    value: 'VI',
    nationality: 'Virgin Islands, U.S.',
  },
  {
    label: 'Wallis and Futuna',
    dial_code: '+681',
    value: 'WF',
    nationality: 'Welsh',
  },
  {
    label: 'Yemen',
    dial_code: '+967',
    value: 'YE',
    nationality: 'Yemenite',
  },
  {
    label: 'Zambia',
    dial_code: '+260',
    value: 'ZM',
    nationality: 'Zambian',
    currLabel: 'Zambia Kwacha - ZMK',
    currValue: 'ZMK',
  },
  {
    label: 'Zimbabwe',
    dial_code: '+263',
    value: 'ZW',
    nationality: 'Zimbabwean',
    currLabel: 'Zimbabwe Dollar - ZWD',
    currValue: 'ZWD',
  },
];

export default countryList;
