import React, { useState } from 'react';
import * as _ from 'lodash';
import { Typography, makeStyles, Box, Grid } from '@material-ui/core';
import NewGoogleButton from '../forms/NewGoogleButton';
import FormInput from '../common/FormInput';
import DoubleText from './DoubleText';
import CustomButton from '../common/CustomButton';
import {
  showSnackbar,
  startPageLoader,
  stopPageLoader,
} from '../../redux/actions/appAction';
import {
  signInWithEmailAndPassword,
  signInWithPopup,
  signOutUser,
} from '../firebase/helper';
import { successRedirectionCopy } from '../common/helper';
import { validateFields } from '../../utils/validator';
import { useDispatch } from 'react-redux';
import {
  GENERIC_EMAIL_LABEL,
  GENERIC_EMAIL_PLACEHOLDER,
  ScreenType,
} from '../../utils/constants';
import { payGetUserRole, payCreateUserMeta } from '../../utils/generic';
import CustomCard from '../common/CustomCard';
import CaptchaValidator from '../common/CaptchaValidator';

const useStyles = makeStyles({
  signupWrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  header: {
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '33px',
    textAlign: 'center',
    color: '#06132D',
    marginBottom: '30px',
  },
  card: {
    padding: '30px',
    boxShadow: '0px 6px 12px rgba(10, 4, 60, 0.15)',
    borderRadius: '10px',
  },
  signUpText: {
    paddingBottom: '30px',
  },
});

const SignIn = ({
  setScreenType,
  user,
  setUser,
  redirectUrlSameDomain,
  testingEnv,
  ...props
}) => {
  const classes = useStyles();
  const _dispatch = useDispatch();

  const [signinForm, setSigningForm] = useState({
    email: props?.inviteUser?.email || '',
    password: '',
  });
  const [error, setError] = useState({
    email: '',
    password: '',
  });

  const [captchaToken, setCaptchaToken] = useState('');

  const validateForm = () => {
    const formError = {};
    const { password, email } = signinForm;

    const validatePassword = validateFields('password', password, {
      screen: ScreenType.SignIn,
    });
    const validateEmail = validateFields('email', email, {
      screen: ScreenType.SignIn,
    });

    _.set(formError, 'password', validatePassword);
    _.set(formError, 'email', validateEmail);

    if (
      _.isEmpty(formError.email) &&
      _.isEmpty(formError.password) &&
      _.isEmpty(formError.name)
    ) {
      return true;
    }

    setError(formError);

    return false;
  };

  const handleChange = e => {
    e.persist();
    setSigningForm(form => ({
      ...form,
      [e.target.name]: e.target.value,
    }));
    setError(error => ({
      ...error,
      [e.target.name]: validateFields(e.target.name, e.target.value, {
        screen: ScreenType.SignIn,
      }),
    }));
  };

  const handleSignInWithGoogle = async () => {
    _dispatch(startPageLoader());
    try {
      const authRes = await signInWithPopup('google.com');
      if (
        !_.isEmpty(props.inviteUser) &&
        authRes?.user?.email !== signinForm.email
      ) {
        throw new Error('Please sign in using your invite email address');
      }

      const meta = payCreateUserMeta(user);
      meta.googleSignIn = true;

      const res = await successRedirectionCopy(props, '', meta);
      if (res?.data?.success) {
        await signOutUser();
        window.location.replace(`${decodeURIComponent(props.redirectURL)}`);
      } else {
        if (!user?.type) {
          const type = res?.data?.meta?.type;
          setUser({
            meta: res?.data?.meta,
            role: payGetUserRole(type),
            type: res.data?.meta?.type,
          });
        }
        setScreenType(ScreenType.AdditionalInfo);
        _dispatch(stopPageLoader());
      }
    } catch (error) {
      _dispatch(stopPageLoader());
      _dispatch(showSnackbar(error.message));
    }
  };

  const handleSigninWithEmailAndPassword = async e => {
    e.preventDefault();
    if (validateForm()) {
      _dispatch(startPageLoader());
      try {
        await signInWithEmailAndPassword(signinForm.email, signinForm.password);

        const meta = payCreateUserMeta(user);
        const res = await successRedirectionCopy(props, '', meta, captchaToken);
        if (res?.data?.success) {
          await signOutUser();
          if (redirectUrlSameDomain) {
            window.location.replace(`${decodeURIComponent(props.redirectURL)}`);
          }
        } else {
          if (!user?.type) {
            const type = res?.data?.meta?.type;
            setUser({
              meta: res?.data?.meta,
              role: payGetUserRole(type),
              type: res?.data?.meta?.type,
            });
          }
          setScreenType(ScreenType.AdditionalInfo);
          _dispatch(stopPageLoader());
        }
      } catch (error) {
        _dispatch(showSnackbar(error.message));
        _dispatch(stopPageLoader());
      }
    }
  };

  return (
    <Box className={classes.signupWrapper}>
      <Typography className={classes.header}>Welcome to Skuad</Typography>

      <NewGoogleButton
        text="Sign in with Google"
        handleClick={handleSignInWithGoogle}
      />
      <Box className="separator" margin="33px 0" width="100%">
        &nbsp; OR &nbsp;
      </Box>
      <form onSubmit={handleSigninWithEmailAndPassword}>
        <CustomCard>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <FormInput
                name="email"
                label={GENERIC_EMAIL_LABEL}
                placeholder={GENERIC_EMAIL_PLACEHOLDER}
                type="email"
                handleChange={handleChange}
                error={!!error.email}
                helperText={error.email}
                defaultValue={props?.inviteUser?.email}
                disabled={!!props?.inviteUser?.email}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <FormInput
                name="password"
                label="Provide your password"
                type="password"
                handleChange={handleChange}
                error={!!error.password}
                helperText={error.password}
              />
            </Grid>
            <CaptchaValidator setCaptchaToken={setCaptchaToken} />
            <Box pl="12px" mt="25px" width="100%">
              <CustomButton
                variant="contained"
                disabled={testingEnv ? !testingEnv : !captchaToken}
                custom
                type="submit"
                fullWidth
              >
                Sign in
              </CustomButton>
            </Box>
            <Box textAlign="center" mt="25px" width="100%">
              <DoubleText
                size={16}
                color="#3E4659"
                suffText="Forgot Password?"
                handleClick={() => setScreenType(ScreenType.ForgotPassword)}
              />
            </Box>
          </Grid>
        </CustomCard>
      </form>
      <Box textAlign="center" mt="30px" className={classes.signUpText}>
        <DoubleText
          size={16}
          color="#3E4659"
          preText="Don't have an account?"
          suffText="Sign up"
          handleClick={() =>
            user?.type
              ? setScreenType(ScreenType.SignUp)
              : setScreenType(ScreenType.UserRole)
          }
        />
      </Box>
    </Box>
  );
};

export default SignIn;
