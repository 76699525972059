import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import ReCAPTCHA from 'react-google-recaptcha';

const CaptchaValidator = props => (
  <Box
    pl="12px"
    mt="20px"
    width="100%"
    display="flex"
    justifyContent="center"
    alignItems="center"
  >
    <ReCAPTCHA
      sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_KEY}
      onChange={val => {
        props.setCaptchaToken(val);
      }}
    />
  </Box>
);

export default CaptchaValidator;
