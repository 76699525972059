import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { Box } from '@material-ui/core';

const useStyles = makeStyles({
  header: {
    fontWeight: 'bold',
    fontSize: '30px',
    lineHeight: '41px',
    marginBottom: '51px',
  },
  unorderedlist: {
    listStyle: 'none',
    margin: '0',
    padding: '0',
    position: 'relative',
    color: '#fff',
    '&::before': {
      content: '""',
      display: 'inline-block',
      width: '2px',
      background: '#fff',
      position: 'absolute',
      left: '3px',
      top: '5px',
      height: 'calc(100% - 110px)',
      marginTop: '-5px',
    },
    '& > li': {
      position: 'relative',
      paddingLeft: '15px',
      marginBottom: '40px',
      '& > div > h3': {
        fontWeight: '600',
        fontSize: '20px',
        marginBottom: '12px',
      },
      '& > div > p': {
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '22px',
      },
      '&::before': {
        content: '""',
        display: 'inline-block',
        width: '8px',
        height: '8px',
        background: '#fff',
        position: 'absolute',
        left: '0',
        top: '5px',
        borderRadius: '10px',
      },
    },
  },
});

const VerticalList = ({ list, header }) => {
  const styles = useStyles();
  return (
    <>
      <Typography className={styles.header}>{header}</Typography>
      <ul className={styles.unorderedlist}>
        {list.map(benefit => (
          <li key={benefit.title}>
            <Box ml="25px">
              <h3>{benefit.title}</h3>
              <p>{benefit.description}</p>
            </Box>
          </li>
        ))}
      </ul>
    </>
  );
};

export default VerticalList;
