import React from 'react';
import ilus from '../../images/error-info.svg';
import errorBg from '../../images/error-info-bg.svg';
import CustomButton from '../common/CustomButton';
import Hidden from '@material-ui/core/Hidden';
import Logo from '../../images/logo.svg';
import Img from '../common/Img';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { Box } from '@material-ui/core';

export default function ErrorPage(props) {
  return (
    <React.Fragment>
      <Box
        className="w-100 h-100 hide-scroll"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        overflow="auto"
      >
        <Box mb="25px">
          <div className="w-100">
            <div className="m-auto">
              <div className="w-100 d-flex">
                <Img style={{ margin: '40px auto' }} src={Logo} />
              </div>
            </div>
          </div>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            className="w-100"
          >
            <div
              className="error-page-illus position-relative"
              style={{ marginTop: '0px' }}
            >
              <img src={errorBg} className="w-100" alt="404" />
              <div className="position-absolute error-icon">
                <img src={ilus} alt="" className="w-100" />
              </div>
            </div>
          </Box>
          <div className="sk-error-ttl w-100">
            {props.errHeading && (
              <>
                <Hidden smDown> {props.errHeading}</Hidden>
                <Hidden mdUp>
                  <div>{props.errHeading}</div>
                </Hidden>{' '}
              </>
            )}
          </div>
          <div className="sk-error-sub-ttl w-100">
            <div style={{ maxWidth: '515px' }} className="m-auto">
              <Hidden smDown> {props.errMessage}</Hidden>
              <Hidden mdUp>
                <div>{props.errMessage}</div>
              </Hidden>
            </div>
            {props.redirectBtnTitle && props.redirectURL && (
              <Box mt={2}>
                <CustomButton
                  variant="outlined"
                  color="secondary"
                  onClick={() => window.location.replace(props.redirectURL)}
                >
                  {props.redirectBtnTitle}
                </CustomButton>
              </Box>
            )}
          </div>
        </Box>
        <Box className="w-100 text-center" mb="25px">
          <span>
            Made with <FavoriteIcon color={'primary'} /> by Skuad's Globally
            Distributed Team
          </span>
        </Box>
      </Box>
    </React.Fragment>
  );
}
