import React, { useEffect, useState } from 'react';
import * as _ from 'lodash';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import AuthLayout from '../components/layout/AuthLayout';
import CEOComment from '../components/AuthComponents/LeftPanel/CEOComment';
import SignIn from '../components/NewForms/SignIn';
import SignUpAsType from '../components/AuthComponents/RightPanel/SignUpAsType';
import TrustingCompanies from '../components/AuthComponents/LeftPanel/TrustingCompanies';
import { checkIfEmailExist } from '../components/firebase/helper';
import { useDispatch } from 'react-redux';
import { startPageLoader, stopPageLoader } from '../redux/actions/appAction';
import { ScreenType, USER_ROLE, USER_TYPE } from '../utils/constants';
import ContractorBenefits from '../components/AuthComponents/LeftPanel/ContractorBenefits';
import EmployeeBenefits from '../components/AuthComponents/LeftPanel/EmployeeBenefits';
import EmployerBenefits from '../components/AuthComponents/LeftPanel/EmployerBenefits';
import CreateAccount from '../components/NewForms/CreateAccount';
import { payGetUserRole } from '../utils/generic';
import BackButton from '../components/common/back';
import ForgotPassword from '../components/NewForms/ForgotPassword';
import ContractorAdditionalInformation from '../components/NewForms/ContractorAdditionalInformation';
import ClientAdditionalInformation from '../components/NewForms/ClientAdditionalInformation';

const getDefaultScreenType = source => {
  const screen = {
    prev: null,
    curr: null,
  };

  if (source) {
    screen.curr = ScreenType.UserRole;
  } else {
    screen.curr = ScreenType.SignIn;
  }

  return screen;
};

const moveScrollToTop = () => {
  const element = document.getElementById('auth-right-panel');
  if (element?.scrollTop) {
    element.scrollTop = 0;
  }
};

const testingEnv =
  process.env.REACT_APP_ENV === 'development' ||
  process.env.REACT_APP_ENV === 'staging';

const PayAuth = props => {
  const location = useLocation();
  const history = useHistory();
  const queryParams = queryString.parse(location.search);
  const {
    invite_id: inviteId,
    source,
    redirect_url: redirectURL,
  } = queryParams || {};

  const [screenType, _setScreenType] = useState([getDefaultScreenType(source)]);
  const [user, setUser] = useState({ role: '', type: '' });
  const [verifyingUser, setVerifyingUser] = useState(true);
  const [redirectUrlSameDomain, setRedirectUrlSameDomain] = useState(false);

  const setScreenType = (value, initial = false) => {
    const lastScreen = screenType[screenType?.length - 1];
    if (initial) {
      _setScreenType([{ prev: null, curr: value }]);
    } else {
      _setScreenType([...screenType, { prev: lastScreen?.curr, curr: value }]);
    }

    moveScrollToTop();
  };

  const goBack = () => {
    const currScreenType = screenType[screenType?.length - 1];
    if (
      [ScreenType.UserRole, ScreenType.SignIn].includes(currScreenType?.prev)
    ) {
      if (!inviteId) {
        setUser({});
      }
    }
    _setScreenType([...screenType.slice(0, screenType?.length - 1)]);

    moveScrollToTop();
  };

  const _dispatch = useDispatch();

  useEffect(() => {
    if (!_.isEmpty(props?.inviteUser)) {
      const verifyUserExistsInFirebase = async () => {
        _dispatch(startPageLoader());
        const userType = props?.inviteUser?.type;
        setUser({ type: userType, role: payGetUserRole(userType) });
        try {
          setVerifyingUser(true);
          const email_exist = await checkIfEmailExist(props?.inviteUser?.email);
          if (email_exist.length) {
            setScreenType(ScreenType.SignIn, true);
          } else {
            setScreenType(ScreenType.SignUp, true);
          }
          setVerifyingUser(false);
          _dispatch(stopPageLoader());
        } catch (err) {
          throw err;
        }
      };
      verifyUserExistsInFirebase();
    } else {
      if (source === 'skuad') {
        setScreenType(ScreenType.UserRole, true);
      }
      setVerifyingUser(false);
    }

    return () => {
      _setScreenType([]);
      setUser({});
    };
  }, []);

  useEffect(() => {
    const URL = window.location;
    let domain = URL.hostname;

    const domainArray = domain.split('.');
    domain = domainArray[1] + '.' + domainArray[2];

    if ((redirectURL && !redirectURL.includes(domain)) || !redirectURL) {
      let newRedirectURL = URL.protocol + '//' + URL.hostname;
      newRedirectURL = newRedirectURL.replace('auth', URL.pathname);
      setRedirectUrlSameDomain(true);
      history.replace({
        pathname: location.pathname,
        search: `?redirect_url=${encodeURIComponent(newRedirectURL)}`,
      });
    } else {
      setRedirectUrlSameDomain(true);
    }
  }, [redirectURL]);

  const handleUserTypeMouseEnter = e => {
    const { id = '' } = e?.target || {};
    setUser({ type: id, role: payGetUserRole(id) });
  };

  const handleUserTypeMouseLeave = () => {};

  const handleUserTypeClick = id => {
    switch (id) {
      case USER_TYPE.Employer:
        setUser({ role: USER_ROLE.Company, type: USER_TYPE.Employer });
        break;
      case USER_TYPE.Contractor:
        setUser({ role: USER_ROLE.Individual, type: USER_TYPE.Contractor });
        break;
      default:
        break;
    }
    setScreenType(ScreenType.SignUp);
  };

  const renderLeftPanel = () => {
    if (
      [ScreenType.ForgotPassword, ScreenType.SignIn].includes(
        screenType[screenType?.length - 1]?.curr
      ) ||
      !user.role
    ) {
      return <TrustingCompanies />;
    } else if (user.type === USER_TYPE.Contractor) {
      return <ContractorBenefits />;
    } else if (user.type === USER_TYPE.Employee) {
      return <EmployeeBenefits />;
    } else if (user.type === USER_TYPE.Employer) {
      return <EmployerBenefits />;
    } else {
      return <CEOComment />;
    }
  };

  const renderExtension = () => {
    const currScreenType = screenType[screenType?.length - 1];
    if (!currScreenType?.prev) {
      return null;
    } else if (
      [ScreenType.SignIn, ScreenType.AdditionalInfo].includes(
        currScreenType?.curr
      )
    ) {
      return null;
    } else if (currScreenType?.curr === ScreenType.UserRole && !!source) {
      return null;
    }

    return <BackButton text="Go Back" handleClick={goBack} />;
  };

  const renderRightPanel = () => {
    const currScreenType = screenType[screenType?.length - 1] || {};
    if (currScreenType?.curr === ScreenType.SignIn) {
      return (
        <SignIn
          user={user}
          setUser={setUser}
          setScreenType={setScreenType}
          redirectURL={redirectURL}
          testingEnv={testingEnv}
          redirectUrlSameDomain={redirectUrlSameDomain}
          {...props}
        />
      );
    } else if (currScreenType?.curr === ScreenType.SignUp) {
      return (
        <CreateAccount
          user={user}
          invited={!!inviteId}
          setUser={setUser}
          setScreenType={setScreenType}
          redirectURL={redirectURL}
          {...props}
        />
      );
    } else if (currScreenType?.curr === ScreenType.ForgotPassword) {
      return (
        <ForgotPassword
          user={user}
          setUser={setUser}
          setScreenType={setScreenType}
          goBack={goBack}
          redirectURL={redirectURL}
          testingEnv={testingEnv}
          {...props}
        />
      );
    } else if (currScreenType?.curr === ScreenType.AdditionalInfo) {
      switch (user.type) {
        case USER_TYPE.Contractor:
          return (
            <ContractorAdditionalInformation
              user={user}
              setUser={setUser}
              setScreenType={setScreenType}
              redirectURL={redirectURL}
              {...props}
            />
          );
        case USER_TYPE.Employer:
          return (
            <ClientAdditionalInformation
              user={user}
              setUser={setUser}
              setScreenType={setScreenType}
              redirectURL={redirectURL}
              {...props}
            />
          );
        default:
          return null;
      }
    } else {
      return (
        <SignUpAsType
          user={user}
          setUser={setUser}
          setScreenType={setScreenType}
          handleClick={handleUserTypeClick}
          handleMouseEnter={handleUserTypeMouseEnter}
          handleMouseLeave={handleUserTypeMouseLeave}
          {...props}
        />
      );
    }
  };

  return (
    <AuthLayout
      left={renderLeftPanel()}
      extension={renderExtension()}
      right={renderRightPanel()}
    />
  );
};

export default PayAuth;
