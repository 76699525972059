import {
  ERROR_MESSAGES,
  MIN_PASSWORD_LENGTH,
  USER_ROLE,
  USER_TYPE,
} from './constants';

export const getURLWithQueryParams = (base, params) => {
  const query = Object.entries(params)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&');

  return `${base}?${query}`;
};

export const queryToObject = queryString => {
  const pairsString =
    queryString[0] === '?' ? queryString.slice(1) : queryString;
  const pairs = pairsString
    .split('&')
    .map(str => str.split('=').map(decodeURIComponent));
  return pairs.reduce(
    (acc, [key, value]) => (key ? { ...acc, [key]: value } : acc),
    {}
  );
};

export const payGetUserRole = userType => {
  switch (userType) {
    case USER_TYPE.Employer:
      return USER_ROLE.Company;
    case USER_TYPE.Contractor:
    case USER_TYPE.Employee:
      return USER_ROLE.Individual;
    default:
      return null;
  }
};

export const payGetUserTypeByRole = userRole => {
  switch (userRole) {
    case USER_ROLE.Individual:
      return USER_TYPE.Contractor;
    case USER_ROLE.Company:
      return USER_TYPE.Employer;
    default:
      return null;
  }
};

export const payCreateUserMeta = (user = {}, rest = {}) => {
  const meta = { ...rest };
  if (user?.type) {
    meta['type'] = user?.type;
  }
  if (user?.role) {
    meta['role'] = user?.role;
  }
  if (user?.role === USER_ROLE.Company) {
    meta['companyName'] = rest?.companyName;
  }

  return meta;
};

export const passwordValidations = [
  {
    message: ERROR_MESSAGES.lowercase_character,
    validator: new RegExp(/[a-z]/),
  },
  {
    message: ERROR_MESSAGES.uppercase_character,
    validator: new RegExp(/[A-Z]/),
  },
  {
    message: ERROR_MESSAGES.numeric_character,
    validator: new RegExp(/[0-9]/),
  },
  {
    message: ERROR_MESSAGES.special_character,
    validator: new RegExp(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~`]/),
  },
  {
    message: ERROR_MESSAGES.invalid_password,
    validator: {
      test: pwd => pwd?.length >= MIN_PASSWORD_LENGTH,
    },
  },
];

export const blockInvalidCharOnNumberType = (event, extraChars = []) => {
  const listOfChars = ['e', 'E', '+', '-', ...extraChars];
  return listOfChars.includes(event.key) && event.preventDefault();
};
