import React, { useEffect } from 'react';
import googleLogo from '../../images/google_icon.svg';
import { Box, Typography, makeStyles } from '@material-ui/core';
import Img from '../common/Img';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    background: '#FFFFFF',
    border: '1px solid #DBDEEB',
    boxShadow: '0px 3px 9px rgba(10, 4, 60, 0.09)',
    borderRadius: '10px',
    width: '100%',
    color: '#06132D',
    padding: '13px 15px',
  },
  text: {
    margin: '0 auto',
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '22px',
  },
});

const NewGoogleButton = ({ redirectURL, handleClick, text = '' }) => {
  const classes = useStyles();

  useEffect(() => {
    if (!redirectURL) return;
  }, [redirectURL]);

  return (
    <Box role="button" onClick={handleClick} className={classes.root}>
      <Img src={googleLogo} alt="google auth icon" />
      <Typography className={classes.text} component="p">
        {text}
      </Typography>
    </Box>
  );
};

export default NewGoogleButton;
