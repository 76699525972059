import React from 'react';
import { Box, Link, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  prefixText: {
    fontWeight: 'normal',
    lineHeight: '19px',
  },
  suffixText: {
    fontWeight: '600',
    lineHeight: '19px',
    color: '#FD4C5C',
  },
});

const DoubleText = ({
  size,
  link,
  color = 'inherit',
  preText,
  suffText,
  handleClick,
}) => {
  const classes = useStyles();
  return (
    <Box fontSize={size} color={color}>
      <span className={classes.prefixText}>{preText}</span>&nbsp;
      {link ? (
        <Link
          rel="noopener noreferrer"
          target="_blank"
          href={link}
          role="button"
          className={classes.prefixText}
        >
          {suffText}
        </Link>
      ) : (
        <Link
          role="button"
          className={classes.prefixText}
          onClick={handleClick}
        >
          {suffText}
        </Link>
      )}
    </Box>
  );
};

export default DoubleText;
