import React from 'react';
import VerticalList from '../../List/VerticalList';

const ContractorBenefits = () => {
  const benefits = [
    {
      title: 'Receive payments in multiple currencies',
      description:
        'Skuad lets you manage multiple clients and bill them in your currency of choice.',
    },
    {
      title: 'Automated invoices, best FX rates',
      description:
        "Never miss a due date again, Skuad auto-generates invoices at the end of your payment cycle and makes sure that you're paid on time.",
    },
    {
      title: 'Amazing benefits and automated compliances',
      description:
        'We understand that managing taxes is painful. Skuad automatically collects the documents you need to stay compliant and effortlessly file taxes.',
    },
  ];
  return <VerticalList list={benefits} header="Contractor Benefits" />;
};

export default ContractorBenefits;
