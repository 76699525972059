const {
  signOutUser,
  getCurrentUser,
  getIdToken,
  isNewUser,
} = require('../firebase/helper');

const { loginFunction } = require('../../api');

const successRedirection = async (props, contact) => {
  try {
    let current_user = await getCurrentUser(props);
    if (!current_user) return;
    if (props.inviteIdentifier && props.inviteExternalId) {
      let extId = '';
      if (props.inviteIdentifier == 'mobile') extId = current_user.phoneNumber;
      if (props.inviteIdentifier == 'email') extId = current_user.email;
      if (
        props.inviteIdentifier != 'custom' &&
        props.inviteExternalId != extId
      ) {
        props.showSnackbar('Invalid Username');
        props.stopPageLoader();
        props.setRootView(null);
        return;
      }
    }

    if (props.loginMode.required) {
      let newUser = false;
      if (props.firstSignup) {
        newUser = await isNewUser();
        props.setFirstSignup(false);
      }
      let required = props.loginMode.required;

      let email_view =
        (required.name && (!current_user.displayName || newUser)) ||
        (required.email && !current_user.email) ||
        (!contact && required.mobile && !current_user.phoneNumber);

      if (email_view) {
        props.setEmailView(email_view);
        props.setRootView('');
        props.stopPageLoader();
        return;
      }
    }

    let idToken = await getIdToken();

    let resp = await loginFunction(
      { idToken, contact, redirectUrl: props.redirectURL },
      props.loginMode.name
    );

    let url = props.redirectURL;
    await signOutUser();
    props.stopPageLoader();
    window.location.replace(`${decodeURIComponent(url)}`);
  } catch (error) {
    throw error;
  }
};

const successRedirectionCopy = async (props, contact, meta, captchaToken) => {
  try {
    let current_user = await getCurrentUser(props);
    if (!current_user) return;
    if (props.inviteIdentifier && props.inviteExternalId) {
      let extId = '';
      if (props.inviteIdentifier === 'mobile') extId = current_user.phoneNumber;
      if (props.inviteIdentifier === 'email') extId = current_user.email;
      if (
        props.inviteIdentifier !== 'custom' &&
        props.inviteExternalId !== extId
      ) {
        throw new Error('Invalid Username');
      }
    }

    if (props.loginMode.required) {
      let newUser = false;
      if (props.firstSignup) {
        newUser = await isNewUser();
        props.setFirstSignup(false);
      }
      let required = props.loginMode.required;

      let email_view =
        // (required.name && (!current_user.displayName || newUser)) ||
        (required.email && !current_user.email) ||
        (!contact && required.mobile && !current_user.phoneNumber);

      if (email_view) {
        throw new Error();
      }
    }

    let idToken = await getIdToken();

    const resp = await loginFunction(
      { idToken, contact, redirectUrl: props.redirectURL, meta, token: captchaToken},
      props.loginMode.name
    );

    return resp;
  } catch (error) {
    throw error;
  }
};

export { successRedirection, successRedirectionCopy };
