import React, { useEffect, useState } from 'react';
import * as _ from 'lodash';
import {
  Typography,
  makeStyles,
  Box,
  Card,
  FormHelperText,
} from '@material-ui/core';
import FormInput from '../common/FormInput';
import { Grid } from '@material-ui/core';
import CustomButton from '../common/CustomButton';
import { validateFields } from '../../utils/validator';
import { successRedirectionCopy } from '../common/helper';
import { signOutUser } from '../firebase/helper';
import {
  showSnackbar,
  startPageLoader,
  stopPageLoader,
} from '../../redux/actions/appAction';
import { useDispatch } from 'react-redux';
import countryList from '../../helper/countryList';

const useStyles = makeStyles({
  wrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#000',
  },
  header: {
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '33px',
    textAlign: 'center',
    color: '#06132D',
    marginBottom: '30px',
  },
  card: {
    padding: '30px',
    boxShadow: '0px 6px 12px rgba(10, 4, 60, 0.15)',
    borderRadius: '10px',
  },
  error: {
    fontSize: '0.75rem',
    color: '#f44336',
  },
});

const ContractorAdditionalInformation = props => {
  const _dispatch = useDispatch();
  const classes = useStyles();

  const geography =
    props?.inviteUser?.geography || props?.user?.meta?.geography || '';

  const [additionalInfo, setAdditionalInfo] = useState({
    geography,
    phone_number: '+',
  });

  useEffect(() => {
    if (geography) {
      const geographyOb = countryList.find(v => v.value === geography);
      if (geographyOb) {
        setAdditionalInfo(oldValue => ({
          ...oldValue,
          geography: geographyOb,
        }));
      }
    }
  }, [geography]);

  const [inputValue, setInputValue] = React.useState('');

  const [error, setError] = useState({
    geography: '',
    phone_number: '',
  });

  const validateForm = () => {
    let formError = {};
    const { phone_number, geography } = additionalInfo;

    const validatePhoneNumber = validateFields('phone', phone_number);
    if (validatePhoneNumber) {
      _.set(formError, 'phone_number', validatePhoneNumber);
    }

    const validateCountry = validateFields('geography', geography);
    if (validateCountry) {
      _.set(formError, 'geography', validateCountry);
    }

    setError(formError);

    if (!_.isEmpty(formError)) {
      return false;
    } else {
      return true;
    }
  };

  const handlePhoneInputChange = e => {
    setAdditionalInfo(form => ({
      ...form,
      phone_number: e.target.value,
    }));
    if (e.target.value !== '+') {
      setError(error => ({
        ...error,
        phone_number: validateFields('phone', e.target.value),
      }));
    }
  };

  const handleDropdownChange = (e, option) => {
    setAdditionalInfo(form => ({
      ...form,
      geography: option,
    }));
    setError(error => ({
      ...error,
      geography: validateFields(option),
    }));
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (validateForm()) {
      _dispatch(startPageLoader());
      try {
        const { geography, phone_number } = additionalInfo;
        const meta = {
          geography: geography.value,
          phone_number,
        };

        const response = await successRedirectionCopy(props, '', meta);

        if (response?.data?.success) {
          await signOutUser();
          window.location.replace(`${decodeURIComponent(props.redirectURL)}`);
          _dispatch(stopPageLoader());
        }
      } catch (error) {
        _dispatch(stopPageLoader());
        _dispatch(showSnackbar(error.message || 'Something went wrong!'));
      }
    }
  };

  return (
    <Box className={classes.wrapper}>
      <Typography className={classes.header}>
        In order to help us serve you better, we need the following additional
        information.
      </Typography>
      <form onSubmit={handleSubmit}>
        <Card className={classes.card}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <FormInput
                id="country-select"
                required
                value={additionalInfo.geography}
                label="Country of tax residence"
                type="dropdown"
                tooltipText="This is the country where your income is taxed"
                handleDropdownChange={handleDropdownChange}
                dropdownInputValue={inputValue}
                dropdownInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                disabled={!!geography}
                formControlError={!!error.geography}
                menuList={countryList}
              />

              <FormHelperText className={classes.error}>
                {error.geography}
              </FormHelperText>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormInput
                id="phone-input"
                required
                name="phone_number"
                value={additionalInfo.phone_number}
                label="Phone Number"
                type="phone"
                handlePhoneInputChange={handlePhoneInputChange}
                formControlError={!!error.phone_number}
                defaultCountry={geography}
              />
              <FormHelperText className={classes.error}>
                {error.phone_number}
              </FormHelperText>
            </Grid>
            <Box pl="12px" mt="25px" mb="15px" width="100%">
              <CustomButton variant="contained" custom type="submit" fullWidth>
                Continue
              </CustomButton>
            </Box>
          </Grid>
        </Card>
      </form>
    </Box>
  );
};

export default ContractorAdditionalInformation;
