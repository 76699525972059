import React, { useState } from 'react';
import * as _ from 'lodash';
import { Typography, makeStyles, Box, Card } from '@material-ui/core';
import FormInput from '../common/FormInput';
import CustomButton from '../common/CustomButton';
import {
  showSnackbar,
  startPageLoader,
  stopPageLoader,
} from '../../redux/actions/appAction';
import { checkIfEmailExist } from '../firebase/helper';
import { validateFields } from '../../utils/validator';
import { useDispatch } from 'react-redux';
import { sendPasswordResetInviteLink } from '../../api';
import { useSnackbar } from 'notistack';
import CaptchaValidator from '../common/CaptchaValidator';

const useStyles = makeStyles({
  forgotPasswordWrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  header: {
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '33px',
    textAlign: 'center',
    color: '#06132D',
    marginBottom: '23px',
  },
  description: {
    fontSize: '16px',
    lineHeight: '26px',
    textAlign: 'center',
    color: '#3E4659',
    marginBottom: '17px',
    width: '70%',
    margin: '0 auto',
  },
  card: {
    padding: '30px',
    boxShadow: '0px 6px 12px rgba(10, 4, 60, 0.15)',
    borderRadius: '10px',
  },
});

const ForgotPassword = ({ goBack, testingEnv, ...props }) => {
  const classes = useStyles();
  const _dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [captchaToken, setCaptchaToken] = useState('');

  const validateForm = () => {
    let error = {};
    let isValidate = true;

    let validateEmail = validateFields('email', email);
    if (validateEmail) {
      _.set(error, 'email', validateEmail);
    }

    if (!_.isEmpty(error)) {
      isValidate = false;
    }

    setError(error);
    return isValidate;
  };

  const handleChange = e => {
    e.persist();
    setEmail(e.target.value);
    setError(validateFields(e.target.name, e.target.value));
  };

  const handleSubmit = async function (e) {
    e.preventDefault();

    if (validateForm()) {
      try {
        _dispatch(startPageLoader());
        const email_exist = await checkIfEmailExist(email);
        if (email_exist.length) {
          await sendPasswordResetInviteLink(props.loginMode.name, {
            email,
            redirectURL: props.redirectURL,
            token: captchaToken,
          });
          enqueueSnackbar(
            'An email with password reset instructions has been sent to your inbox.',
            { key: 'forgotPassword', variant: 'success' }
          );
        } else {
          _dispatch(showSnackbar('Entered Email does not exist'));
        }
        goBack();
        _dispatch(stopPageLoader());
        return;
      } catch (error) {
        _dispatch(showSnackbar(error.message));
        _dispatch(stopPageLoader());
        return;
      }
    }
  };

  return (
    <Box className={classes.forgotPasswordWrapper}>
      <form style={{ width: '100%' }} onSubmit={handleSubmit}>
        <Card className={classes.card}>
          <Typography className={classes.header}>Forgot Password?</Typography>
          <Typography className={classes.description}>
            Enter your email below to receive your password reset instructions.
          </Typography>
          <FormInput
            name="email"
            label="Email Address"
            placeholder="E.g. you@gmail.com"
            type="email"
            handleChange={handleChange}
            error={!!error.email}
            helperText={error.email}
          />
          <CaptchaValidator setCaptchaToken={setCaptchaToken} />
          <Box mt="25px" mb="15px" width="100%">
            <CustomButton
              variant="contained"
              disabled={testingEnv ? !testingEnv : !captchaToken}
              custom
              type="submit"
              fullWidth
            >
              Send
            </CustomButton>
          </Box>
        </Card>
      </form>
    </Box>
  );
};

export default ForgotPassword;
