export const API_MOBILE_AUTH = '/auth/auth_process/mobile';
export const API_AUTH_PROCESS = '/auth/auth_process';
export const API_LINKEDIN_SUCCESS = '/auth/auth_process/linkedin';
export const API_VERIFY_EMAIL = '/auth/auth_process/verify_email';

export const API_ENDPOINTS = {
  hire: '/auth/hire',
  allremote: '/auth/allremote',
  company: '/auth/company',
  admin: '/auth/admin',
  pay: '/auth/pay',
  invite: '/auth/invite',
  'reset-password': 'auth/',
};
