import React from 'react';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';

const breakpointValues = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1920,
};

const typographyMap = () => {
  var style = (fontSize = '16px', margin = '0 0 0.5rem 0') => {
    return {
      fontSize: fontSize,
      margin: margin,
      fontWeight: 'bold',
    };
  };
  return {
    h1: style('2em'),
    h2: style('1.5em'),
    h3: style('1.17em'),
    h4: style('1em'),
    h5: style('.83em'),
    h6: style('.67em'),
    p: style('.875em'),
  };
};

const buttonMap = () => {
  return {
    text: {
      textTransform: 'none',
    },
    root: {
      textTransform: 'none',
    },
  };
};

const theme = createTheme({
  palette: {
    primary: {
      main: '#e61d17',
    },
    secondary: {
      main: '#184AAA',
      '& > .Mui-focused': {
        color: 'var(--sk-black)',
      },
    },
    contrastThreshold: 3,
    tonalOffset: 0.05,
  },
  typography: {
    fontFamily: `"Open Sans", sans-serif, color-emoji`,
  },
  breakpoints: { values: breakpointValues },
  overrides: {
    MuiButton: buttonMap(),
    MuiTypography: typographyMap(),
    MuiOutlinedInput: {
      input: {
        padding: '15.5px 14px',
      },
      root: {
        '&$focused': {
          '& [class*="MuiOutlinedInput-notchedOutline"]': {
            border: '1px solid #3E4659',
            borderWidth: '1px',
          },
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: 'black',
        'Mui-error': {
          color: '#f44336',
          border: 'unset',
        },
      },
    },
  },
});

export default function (props) {
  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
}
