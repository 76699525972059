import React, { useState, useEffect } from 'react';
import { Box, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
//import FavoriteIcon from '@material-ui/icons/Favorite';
import Img from '../common/Img';
import LOGIN_MODES from '../common/config';

const LayoutComponent = props => {
  const themeObj = useTheme();
  const isMobile = useMediaQuery(themeObj.breakpoints.down('md'));
  const [view, setView] = useState('root');
  const [heading, setHeading] = useState('');
  const [subHeading, setSubHeading] = useState('');
  const [title, setTitle] = useState('');
  const [textArray, setTextArray] = useState([]);

  useEffect(() => {
    if (props.loginMode) {
      setHeading(LOGIN_MODES[props.loginMode].views.heading);
      setSubHeading(LOGIN_MODES[props.loginMode].views.subHeading);
      setTitle(LOGIN_MODES[props.loginMode].views.data?.title);
      setTextArray(LOGIN_MODES[props.loginMode].views.data?.textArray);
    }
  }, []);

  return (
    <Box
      height="100%"
      width="100%"
      className="container-fluid px-0"
      style={{
        overflowX: 'hidden',
        overflowY: 'auto',
        backgroundImage:
          LOGIN_MODES[props.loginMode].css && isMobile
            ? LOGIN_MODES[props.loginMode].css.mobilBackgroundImage
            : '',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <div className="d-md-flex d-block h-100">
        <div className="w-100 d-lg-none d-md-block">
          <div className="w-100 d-flex">
            <Img
              style={{
                margin: LOGIN_MODES[props.loginMode].css
                  ? LOGIN_MODES[props.loginMode].css.logoMarginMobile
                  : '40px auto',
              }}
              src={
                LOGIN_MODES[props.loginMode].logoMobile ||
                LOGIN_MODES[props.loginMode].logo
              }
            />
          </div>
        </div>
        <div
          className="d-none d-lg-block col-md-4 col-lg-5 col-xl-4 h-100 px-0"
          style={{
            position: LOGIN_MODES[props.loginMode].css
              ? LOGIN_MODES[props.loginMode].css.leftPanelPosition
              : 'relative',
          }}
        >
          <Box
            style={{
              padding: LOGIN_MODES[props.loginMode].css
                ? LOGIN_MODES[props.loginMode].css.padding
                : '0px',
              maxWidth: LOGIN_MODES[props.loginMode].css
                ? LOGIN_MODES[props.loginMode].css.leftPanelWidth
                : '480px',
              background: LOGIN_MODES[props.loginMode].css
                ? LOGIN_MODES[props.loginMode].css.backgroundColor
                : '#f7f8fa',
              backgroundImage:
                LOGIN_MODES[props.loginMode].css && !isMobile
                  ? LOGIN_MODES[props.loginMode].css.backgroundImage
                  : '',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }}
            position="fixed"
            width="100%"
            height="100%"
          >
            <Img
              style={{
                margin: LOGIN_MODES[props.loginMode].css
                  ? LOGIN_MODES[props.loginMode].css.logoMargin
                  : '40px',
              }}
              src={LOGIN_MODES[props.loginMode].logo}
            />
            {title && (
              <Box my={4} color={'white'} style={{ minWidth: 'max-content' }}>
                <h1 color={'white'}>{title}</h1>
              </Box>
            )}
            {textArray?.map(textObj => (
              <Box color={'white'} my={3}>
                <Typography variant="h3" gutterBottom component="div">
                  {textObj?.heading}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {textObj?.text}
                </Typography>
              </Box>
            ))}
          </Box>

          {LOGIN_MODES[props.loginMode].views[view].illustration && (
            <Box
              top="0"
              left="80px"
              height="100%"
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              position="absolute"
            >
              <Img
                className=""
                src={LOGIN_MODES[props.loginMode].views[view].illustration}
              />
            </Box>
          )}

          {LOGIN_MODES[props.loginMode].views[view].text && (
            <div className="left-panel-text">
              <div className="">
                {LOGIN_MODES[props.loginMode].views[view].text}
              </div>
            </div>
          )}
          {/* <div
            className="position-absolute global-tag-line"
            style={{ margin: '40px', bottom: '-4px' }}
          >
            <span>
              Made with <FavoriteIcon color={'primary'} /> by Skuad's Globally
              Distributed Team
            </span>
          </div> */}
        </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-8 px-0 overflow-x-auto py-md-5">
          <div className="w-100 h-100 d-flex flex-grow-1 justify-content-center">
            {React.cloneElement(props.children, {
              setView,
              view,
              setHeading,
              setSubHeading,
              heading,
              subHeading,
              title,
              textArray,
              css: LOGIN_MODES[props.loginMode].css,
              isMobile,
            })}
            <div id="toast_container"></div>
          </div>
        </div>
      </div>
      {/* <div className="row h-100">
      <div className="d-none d-lg-block col-md-4 col-lg-4 col-xl-4 h-100 px-0">
        <div className="w-100 h-100 d-flex align-items-end justify-content-start">
          <Img className="ml-3" src={LOGIN_MODES[props.loginMode].illustration.left} />
        </div>
      </div>

      <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 px-0">
        <div className="w-100 h-100 d-flex flex-grow-1 flex-column justify-content-center">
          {props.children}
          <div id="toast_container"></div>
        </div>
      </div>

      <div className="d-none d-lg-block col-md-4 col-lg-4 col-xl-4 h-100 px-0">
        <div className="w-100 h-100 d-flex align-items-end justify-content-end">
          <Img className="mr-3" src={LOGIN_MODES[props.loginMode].illustration.right} />
        </div>
      </div>
    </div> */}
    </Box>
  );
};

export default LayoutComponent;
