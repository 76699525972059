import React, { useState } from 'react';
import {
  Typography,
  makeStyles,
  Box,
  RadioGroup,
  FormHelperText,
  Grid,
} from '@material-ui/core';
import FormInput from '../common/FormInput';
import CustomButton from '../common/CustomButton';
import { successRedirectionCopy } from '../common/helper';
import {
  showSnackbar,
  startPageLoader,
  stopPageLoader,
} from '../../redux/actions/appAction';
import { signOutUser } from '../firebase/helper';
import { useDispatch } from 'react-redux';
import { CLIENT_PLAN, ERROR_MESSAGES } from '../../utils/constants';
import { blockInvalidCharOnNumberType } from '../../utils/generic';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#000',
  },
  header: {
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '25px',
    textAlign: 'center',
    color: '#06132D',
    marginBottom: '30px',
  },
  error: {
    paddingLeft: '12px',
  },
});

const blockedChars = ['.'];

const ClientAdditionalInformation = props => {
  const classes = useStyles();
  const _dispatch = useDispatch();

  const [radioValue, setRadioValue] = useState('');
  const [formName, setFormName] = useState('');
  const [formValue, setFormValue] = useState('');
  const [form, setForm] = useState({
    contractors: '',
    employees: '',
    contractorsAndEmployees: '',
  });
  const [formError, setFormError] = useState({
    contractors: '',
    employees: '',
    contractorsAndEmployees: '',
  });

  const [helperErrorText, setHelperErrorText] = useState('');

  const handleRadioChange = e => {
    if (e.target.name === 'client') {
      setHelperErrorText('');
    }
    setRadioValue(e.target.value);
    setFormName(e.target.value);
  };

  const getErrorMessageByFormName = (name = formName) => {
    switch (name) {
      case CLIENT_PLAN.Contractors:
        return ERROR_MESSAGES.onboard_contractor;
      case CLIENT_PLAN.Employees:
        return ERROR_MESSAGES.onboard_employee;
      case CLIENT_PLAN.ContractorsAndEmployees:
        return ERROR_MESSAGES.onboard_contractorsAndEmployees;
      default:
        return '';
    }
  };

  const validateFields = (name, value) => {
    if (name === 'client') {
      if (!value) return ERROR_MESSAGES.no_option;
      return '';
    }
    if (+value <= 0) return getErrorMessageByFormName(name);
    else return '';
  };

  const isFormValid = () => {
    if (!radioValue) return ERROR_MESSAGES.no_option;
    if (!helperErrorText && !formError[formName] && formValue > 0) {
      return '';
    }

    return getErrorMessageByFormName();
  };

  const handleFormChange = e => {
    e.persist();
    const { target: { name = '', value = '' } = {} } = e;

    if (name === 'client') {
      setHelperErrorText(validateFields(name, value));
    } else if (name) {
      setFormName(name);
      setFormValue(value !== '' ? +value : '');
      setForm(form => ({
        ...form,
        [name]: value !== '' ? +value : '',
      }));
      setFormError({ [name]: validateFields(name, value) });
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const error = isFormValid();
    if (!error?.length) {
      _dispatch(startPageLoader());
      try {
        const meta = {
          plan: {
            [formName]: formValue,
          },
        };

        const response = await successRedirectionCopy(props, '', meta);

        if (response?.data?.success) {
          await signOutUser();
          window.location.replace(`${decodeURIComponent(props.redirectURL)}`);
          _dispatch(stopPageLoader());
        }
      } catch (error) {
        _dispatch(stopPageLoader());
        _dispatch(showSnackbar(error.message));
      }
    } else {
      if (!radioValue) {
        setHelperErrorText(error);
      } else {
        setFormError({ [formName]: error });
      }
    }
  };

  return (
    <Box className={`${classes.wrapper}`}>
      <Typography className={classes.header}>
        In order to help us serve you better, we need the following additional
        information.
      </Typography>

      <form onSubmit={handleSubmit}>
        <Box width="100%" textAlign="left">
          <RadioGroup
            aria-label="radio"
            name="client"
            value={radioValue}
            onChange={handleRadioChange}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <FormInput
                  id="contractors"
                  type="number"
                  min="0"
                  radioValue={CLIENT_PLAN.Contractors}
                  radioLabel="I want to pay & manage contractors"
                  radioSelected={radioValue}
                  radioInputType="radio"
                  label="What is the expected number of contractors?"
                  name="contractors"
                  value={form.contractors}
                  onKeyDown={e => blockInvalidCharOnNumberType(e, blockedChars)}
                  handleChange={handleFormChange}
                  required={formName === CLIENT_PLAN.Contractors}
                  error={!!formError.contractors}
                  helperText={
                    !!formError.contractors?.length && formError.contractors
                  }
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <FormInput
                  id="employees"
                  type="number"
                  min="0"
                  radioValue={CLIENT_PLAN.Employees}
                  radioLabel="I want to pay & manage employees"
                  radioSelected={radioValue}
                  radioInputType="radio"
                  label="What is the expected number of employees?"
                  name="employees"
                  value={form.employees}
                  onKeyDown={e => blockInvalidCharOnNumberType(e, blockedChars)}
                  handleChange={handleFormChange}
                  required={formName === CLIENT_PLAN.Employees}
                  error={!!formError.employees}
                  helperText={!!formError.employees && formError.employees}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <FormInput
                  id="contractorsAndEmployees"
                  type="number"
                  min="0"
                  radioValue={CLIENT_PLAN.ContractorsAndEmployees}
                  radioLabel="I want to pay & manage both"
                  radioSelected={radioValue}
                  radioInputType="radio"
                  label="What is the expected number of contractors and employees?"
                  name="contractorsAndEmployees"
                  value={form.contractorsAndEmployees}
                  onKeyDown={e => blockInvalidCharOnNumberType(e, blockedChars)}
                  handleChange={handleFormChange}
                  required={formName === CLIENT_PLAN.ContractorsAndEmployees}
                  error={!!formError.contractorsAndEmployees}
                  helperText={
                    !!formError.contractorsAndEmployees &&
                    formError.contractorsAndEmployees
                  }
                />
              </Grid>
            </Grid>

            <Box mt={1}>
              {!!helperErrorText && (
                <FormHelperText
                  error={!!helperErrorText}
                  className={classes.error}
                >
                  {helperErrorText}
                </FormHelperText>
              )}
            </Box>

            <Box mt="25px" mb="15px">
              <CustomButton variant="contained" custom type="submit" fullWidth>
                Continue
              </CustomButton>
            </Box>
          </RadioGroup>
        </Box>
      </form>
    </Box>
  );
};

export default ClientAdditionalInformation;
