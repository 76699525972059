import React from 'react';
import { Grid, Box, makeStyles, Typography } from '@material-ui/core';

import { ReactComponent as Narrative } from '../../../images/auth/companies/narrative.svg';
import { ReactComponent as LeadIQ } from '../../../images/auth/companies/leadiq.svg';
import { ReactComponent as TalkRemit } from '../../../images/auth/companies/talkremit.svg';
import { ReactComponent as Wheelhouse } from '../../../images/auth/companies/wheelhouse.svg';
import { ReactComponent as Avlino } from '../../../images/auth/companies/avlino.svg';
import { ReactComponent as Neudesic } from '../../../images/auth/companies/neudesic.svg';

const useStyles = makeStyles({
  AuthLandingText: {
    fontWeight: 'bold',
    fontSize: '40px',
    lineHeight: '50px',
    letterSpacing: '1px',
  },
});

const TrustingCompanies = () => {
  const styles = useStyles();
  const companiesThatTrustsUs = [
    Narrative,
    LeadIQ,
    TalkRemit,
    Wheelhouse,
    Avlino,
    Neudesic,
  ];
  return (
    <>
      <Typography className={styles.AuthLandingText}>
        Companies that
        <br />
        trust us for
        <br />
        remote hiring &<br />
        global payroll
      </Typography>
      <Box mt="60px" ml="9px" className="w-100">
        <Grid container spacing={0}>
          {companiesThatTrustsUs.map((Company, i) => (
            <Grid
              item
              md={6}
              lg={4}
              key={i}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <Company />
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default TrustingCompanies;
