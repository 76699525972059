import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Cookies from 'js-cookie';
import { makeStyles } from '@material-ui/core';
import ErrorPage from '../components/error/Error';
import * as actions from '../redux/actions/appAction';
import EmailVerifyOutput from '../components/forms/EmailVerifyOutput';
import { queryToObject } from '../utils/generic';
import {
  checkActionCode,
  applyActionCode,
  signInWithEmailLink,
} from '../components/firebase/helper';
import { verifyEmail } from '../api';
import AuthLayout from '../components/layout/AuthLayout';
import CEOComment from '../components/AuthComponents/LeftPanel/CEOComment';

const useStyles = makeStyles({
  w100: {
    width: '100%',
  },
});

const EmailVerify = props => {
  const classes = useStyles();
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    'Sorry, it seems the email verification has expired. Request you to please login to the portal and resend the email verification link again.'
  );
  const [showVerifyEmail, setShowVerifyEmail] = useState(false);
  const [showVerifySecondaryEmail, setShowVerifySecondaryEmail] =
    useState(false);
  const [redirectURL, setRedirectUrl] = useState('');
  const [code, setCode] = useState('');
  const [redirectButtonTitle, setRedirectButtonTitle] =
    useState('Proceed to Login');
  const mode = props.match.params.mode;

  useEffect(() => {
    let params = queryToObject(window.location.search);
    const code = params['oobCode'];
    const verifMode = params.mode;
    let continueUrl = params['continueUrl'];
    if (continueUrl) {
      setRedirectUrl(continueUrl);
    }
    // const email = params['email'];
    if (verifMode !== 'verifySecondaryEmail') {
      if (!code) {
        setErrorMessage('INVALID EMAIL VERIFICATION LINK!');
        return setShowError(true);
      }
      setCode(code);
      props.startPageLoader();
      (async () => {
        try {
          await checkActionCode(code);
          await applyActionCode(code);
          await verifyEmail();
          setShowVerifyEmail(true);

          // await signInWithEmailLink(email);
          // setShowVerifyEmail(true)
          props.stopPageLoader();
        } catch (error) {
          setShowError(true);
          props.stopPageLoader();
        }
      })();
    } else {
      setShowVerifySecondaryEmail(true);
    }
  }, []);

  const verifySeconadaryEmail = async data => {
    try {
      props.startPageLoader();
      await verifyEmail(data);
      setShowVerifyEmail(true);
      props.stopPageLoader();
    } catch (error) {
      if (error?.code === 'EXPIRED') {
        setRedirectButtonTitle('Proceed to Login');
        setErrorMessage(error?.message);
      }

      // const errData = error?.response?.data;
      // if(errData?.error || errData?.message){
      //   setErrorMessage(errData?.error || errData?.message)
      // }
      setShowError(true);
      props.stopPageLoader();
    }
  };

  return (
    <>
      {showError && (
        <>
          <ErrorPage
            header={true}
            {...props}
            errMessage={errorMessage}
            redirectBtnTitle={redirectButtonTitle}
            redirectURL={redirectButtonTitle && redirectURL}
          />
        </>
      )}
      {showVerifyEmail && (
        <AuthLayout
          left={<CEOComment />}
          right={
            <EmailVerifyOutput
              {...props}
              mode={mode}
              code={code}
              redirectURL={redirectURL}
            />
          }
        />
      )}
      {showVerifySecondaryEmail && (
        <AuthLayout
          left={<CEOComment />}
          className={classes.w100}
          right={
            <EmailVerifyOutput
              {...props}
              onSubmit={verifySeconadaryEmail}
              isSecondaryEmail
            />
          }
        />
      )}
    </>
  );
};

const mapStateToProps = state => ({
  ...state,
});
const mapDispatchToProps = {
  ...actions,
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EmailVerify)
);
