import React, { useState, useEffect } from 'react';
import * as _ from 'lodash';
import { Button, TextField } from '@material-ui/core';
import BackButton from '../common/back';
import ThankyouText from '../common/ThankyouText';
import {
  validateFields,
  checkResponseType,
  statusCodeValidate,
} from '../../utils/validator';
import { checkIfEmailExist } from '../firebase/helper';
import { sendPasswordResetInviteLink } from '../../api';
import ErrorText from '../common/ErrorText';

const ForgotPasswordEmailInput = props => {
  const [email, setEmail] = useState('');
  const [thankyou, setThankyou] = useState(false);
  const [error, setError] = useState({});
  useEffect(() => {
    props.setView('forgot-email');
  }, []);
  const validateForm = () => {
    let error = {};
    let isValidate = true;

    let validateEmail = validateFields('email', email);
    if (validateEmail) {
      _.set(error, 'email', validateEmail);
    }

    if (!_.isEmpty(error)) {
      isValidate = false;
    }

    setError(error);
    return isValidate;
  };

  const handleSubmit = async function (e) {
    e.preventDefault();
    if (validateForm()) {
      try {
        props.startPageLoader();
        let email_exist = await checkIfEmailExist(email);
        if (!email_exist.length) {
          props.showSnackbar("Entered Email doesn't Exist");
          return props.stopPageLoader();
        }
        await sendPasswordResetInviteLink(props.loginMode.name, {
          email,
          redirectURL: props.redirectURL,
        });
        props.stopPageLoader();
        setThankyou(true);
      } catch (error) {
        props.showSnackbar(error.message);
        props.stopPageLoader();
        return;
      }
    }
  };

  return (
    <>
      {thankyou ? (
        <>
          <ThankyouText
            {...props}
            subtext={
              'An email with password reset instructions has been sent to your inbox.'
            }
            continueClick={props.backClick}
          />
        </>
      ) : (
        <>
          <BackButton {...props} />
          <form onSubmitCapture={e => handleSubmit(e)}>
            <div className="row">
              <div className="form-group col-12 col-sm-12 col-md-12 mt-4 mb-1">
                <label className="sk-up-ob-pf-form-lbl">
                  Email <span className="sk-red-txt">*</span>{' '}
                </label>
                <TextField
                  className="w-100"
                  name="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  error={!_.isEmpty(_.get(error, ['email'], ''))}
                  variant="outlined"
                />
                <ErrorText
                  error={error}
                  name={'email'}
                  tooltip={'Email must include @'}
                />
              </div>
            </div>

            <div className="text-center">
              <Button
                className="w-100 form-action-button"
                variant="contained"
                color="primary"
                onClick={e => handleSubmit(e)}
              >
                Send
              </Button>
            </div>
          </form>
        </>
      )}
    </>
  );
};

export default ForgotPasswordEmailInput;
