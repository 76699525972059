import React from 'react';
import VerticalList from '../../List/VerticalList';

const EmployeeBenefits = () => {
  const benefits = [
    {
      title: 'Seamless onboarding journey',
      description:
        'Submit all your documents and provide all onboarding specific information in one single guided flow.',
    },
    {
      title: 'E-sign employee agreements',
      description:
        'Sign and manage all your contract specific documents in one single repository.',
    },
    {
      title: 'Easily manage leaves',
      description:
        'Track available leaves and apply and seek approval for leaves on the platform itself.',
    },
  ];
  return <VerticalList list={benefits} header="Employee Benefits" />;
};

export default EmployeeBenefits;
