import firebase from './index';
import api from '../../api';
import { FIREBASE_ERROR_MESSAGES } from '../../utils/constants';

const getProvider = provider => {
  switch (provider) {
    case 'google.com':
      return new firebase.auth.GoogleAuthProvider();
      break;
    case 'facebook.com':
      return new firebase.auth.FacebookAuthProvider();
      break;
    default:
      break;
  }
};

const signInWithPopup = async provider => {
  provider = getProvider(provider);
  try {
    return await firebase.auth().signInWithPopup(provider);
    let idToken = await getIdToken();
  } catch (error) {
    throwError(error);
  }
};

const signInWithEmailAndPassword = async (email, password) => {
  try {
    await firebase.auth().signInWithEmailAndPassword(email, password);
    let idToken = await getIdToken();
  } catch (error) {
    throwError(error);
  }
};

const checkIfEmailExist = async email => {
  try {
    return await firebase.auth().fetchSignInMethodsForEmail(email);
  } catch (error) {
    return [];
  }
};

const signInWithEmailLink = async email => {
  if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
    try {
      await firebase.auth().signInWithEmailLink(email, window.location.href);
      // await firebase.auth().currentUser.updatePassword(password);
      return true;
    } catch (error) {
      return false;
    }
  } else return false;
};

const signUpWithEmailAndPassword = async (email, password) => {
  try {
    await firebase.auth().createUserWithEmailAndPassword(email, password);
    let idToken = await getIdToken();
  } catch (error) {
    throwError(error);
  }
};

const signInWithPhoneNumber = async number => {
  try {
    return await firebase
      .auth()
      .signInWithPhoneNumber(number, window.recaptchaVerifier);
  } catch (error) {
    throwError(error);
  }
};

const verifyOTP = async (confirmationResult, code) => {
  try {
    return await confirmationResult.confirm(code);
    // let idToken = await getIdToken();
    // return idToken;
  } catch (error) {
    throwError(error);
  }
};

const getIdToken = async () => {
  try {
    return await firebase.auth().currentUser.getIdToken(true);
  } catch (error) {
    throwError(error);
  }
};

const getCurrentUser = async props => {
  try {
    let user = await firebase.auth().currentUser;
    if (!user) {
      props.showSnackbar &&
        props.showSnackbar('Please login again to continue');
      props.stopPageLoader && props.stopPageLoader();
      props.setRootView && props.setRootView(null);
      return;
    }
    return user;
  } catch (error) {
    throwError(error);
  }
};

const sendPasswordResetEmail = async (email, url) => {
  try {
    return await firebase.auth().sendPasswordResetEmail(email, { url });
  } catch (error) {
    throwError(error);
  }
};

const verifyPasswordResetCode = async code => {
  try {
    return await firebase.auth().verifyPasswordResetCode(code);
  } catch (error) {
    throwError(error);
  }
};

const checkActionCode = async code => {
  try {
    return await firebase.auth().checkActionCode(code);
  } catch (error) {
    throwError(error);
  }
};

const applyActionCode = async code => {
  try {
    return await firebase.auth().applyActionCode(code);
  } catch (error) {
    throwError(error);
  }
};

const confirmPasswordReset = async (code, password) => {
  try {
    return await firebase.auth().confirmPasswordReset(code, password);
  } catch (error) {
    throwError(error);
  }
};

const updateUserDetails = async (name, email) => {
  try {
    var user = firebase.auth().currentUser;
    if (email) await user.updateEmail(email);
    if (name) await user.updateProfile({ displayName: name });
    return true;
  } catch (error) {
    throwError(error);
  }
};

const signOutUser = async () => {
  return await firebase.auth().signOut();
};
const throwError = error => {
  let errorMessage = FIREBASE_ERROR_MESSAGES[error.code];

  if (errorMessage) error.message = errorMessage;
  else error.message = 'something went wrong';
  throw error;
};

const isNewUser = async () => {
  try {
    const metadata = await firebase.auth().currentUser.metadata;
    return metadata.lastSignInTime === metadata.creationTime;
  } catch (error) {
    return false;
  }
};

export {
  getProvider,
  signInWithPopup,
  getIdToken,
  signInWithEmailAndPassword,
  signUpWithEmailAndPassword,
  signInWithPhoneNumber,
  verifyOTP,
  getCurrentUser,
  updateUserDetails,
  signOutUser,
  checkIfEmailExist,
  signInWithEmailLink,
  sendPasswordResetEmail,
  verifyPasswordResetCode,
  confirmPasswordReset,
  checkActionCode,
  isNewUser,
  applyActionCode,
};
