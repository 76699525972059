import React from 'react';
import { Box, Card, Typography, makeStyles } from '@material-ui/core';
import employerIllus from '../../../images/auth/employer.svg';
import contractorIllus from '../../../images/auth/contractor.svg';
import { USER_TYPE } from '../../../utils/constants';
import CustomCard from '../../common/CustomCard';

const useStyles = makeStyles(theme => ({
  // Card style
  root: {
    padding: '25px',
    display: 'flex',
    boxShadow: '0px 6px 12px rgba(10, 4, 60, 0.15)',
    borderRadius: '10px',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  cover: {
    width: 150,
    height: '100px',
    overflow: 'auto',
  },
  content: {
    padding: 'unset',
    '& > h1': {
      fontWeight: '600',
      fontSize: '20px',
      lineHeight: '27px',
      color: '#06132D',
      marginBottom: '16px',
    },
  },
  // card style end

  signUpAsTypeContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    color: '#06132D',
    maxHeight: '100%',
    overflow: 'auto',
    paddingLeft: 10,
    paddingRight: 10,
  },
  welcome: {
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '33px',
    marginBottom: '15px',
  },
  description: {
    lineHeight: '26px',
    color: '#3E4659',
  },
  cardImage: {
    display: 'flex',
    justifyContent: 'center',
  },
  centeredText: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center',
    },
    '& > h1': {
      fontWeight: '600',
      fontSize: '20px',
      lineHeight: '27px',
      color: '#06132D',
      marginBottom: '16px',
    },
  },
}));

const CardType = ({
  illustration,
  mediaTitle,
  header,
  description,
  handleMouseEnter,
  handleMouseLeave,
  handleClick,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <CustomCard
      className={classes.root}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      {...rest}
    >
      <Box className={classes.cardImage}>
        <img className={classes.cover} src={illustration} alt={mediaTitle} />
      </Box>
      <Box className={classes.content}>
        <Box className={classes.centeredText}>
          <Typography component="h1">{header}</Typography>
        </Box>
        <Box className={classes.centeredText}>
          <Typography component="p" className={classes.description}>
            {description}
          </Typography>
        </Box>
      </Box>
    </CustomCard>
  );
};

const SignUpAsType = ({ handleMouseEnter, handleMouseLeave, handleClick }) => {
  const classes = useStyles();
  return (
    <Box className={`${classes.signUpAsTypeContainer} hide-scroll`} pb={1}>
      <Box
        textAlign="center"
        onMouseEnter={handleMouseEnter}
        onMouseOver={() => handleMouseEnter({})}
      >
        <Typography className={classes.welcome}>Welcome to Skuad</Typography>
        <Typography component="p" className={classes.description}>
          Global HR platform that automates everything related to remote work
          including onboarding, compliances, benefits, invoices, and payments
        </Typography>
      </Box>
      <Box mt="40px">
        <CardType
          id={USER_TYPE.Employer}
          handleMouseEnter={handleMouseEnter}
          handleMouseLeave={handleMouseLeave}
          onMouseOver={() =>
            handleMouseEnter({ target: { id: USER_TYPE.Employer } })
          }
          handleClick={() => handleClick(USER_TYPE.Employer)}
          illustration={employerIllus}
          mediaTitle="Employer illustration"
          header="Sign up as an employer"
          description="Hire, pay and manage employees and contractors globally"
        />
        <Box mt="30px">
          <CardType
            id={USER_TYPE.Contractor}
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
            onMouseOver={() =>
              handleMouseEnter({ target: { id: USER_TYPE.Contractor } })
            }
            handleClick={() => handleClick(USER_TYPE.Contractor)}
            illustration={contractorIllus}
            mediaTitle="Contractor illustration"
            header="Sign up as a contractor"
            description="Manage clients, timesheets, invoices,
            cross border payments - all in one dashboard"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default SignUpAsType;
